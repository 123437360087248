import { Heading, List, ListItem } from '@vaisala/rockhopper-components';
import { useState, useEffect } from 'react';
import UpdateFilesConfirmation from '../../../components/UpdateFilesConfirmation';
import requests from '../../../utils/requests';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import c from '../../../utils/commands';
import '../Overview.css';

const FilesList = ({ sendFileToSystems, toggle, user, systemsToUpdate }) => {
    const INFORMATIVE_MODAL_DISCARD_OPTION = false;

    // Confirmation modal (send file or delete file)
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
    const [confirmationFunction, setConfirmationFunction] = useState(() => () => {});
    // Update files available
    const [fileList, setFileList] = useState([]);

    const getFileList = async () => {
        try {
            const { data } = await requests.get('update/list');
            setFileList(data);
        } catch (_err) {
            NotificationManager.error('Server error while retrieving list');
        }
    };

    const getFileToken = async (filename) => {
        try {
            const { data } = await requests.get('update/download_token/' + filename);
            return data.token;
        } catch (_err) {
            NotificationManager.error('Server error while retrieving download link');
        }
        return null;
    };

    const getFileChecksum = async (filename) => {
        try {
            const { data } = await requests.get('update/checksum/' + filename);
            return data;
        } catch (_err) {
            NotificationManager.error('Cannot get the file checksum');
        }
        return null;
    };

    useEffect(() => {
        getFileList();
    }, []);

    const handleConfirmationMessage = () => {
        setConfirmationFunction(() => () => {
            setShowConfirmationMessage(false);
            toggle(false);
        });
        setShowConfirmationMessage(
            'The file transfer process is currently underway. \n A status email will be sent at the conclusion of the process.'
        );
    };

    const fileSelectedNotConfirmed = (file) => {
        setConfirmationFunction(() => () => {
            fileSelectedConfirmed(file);
            setShowConfirmation(false);
            setShowConfirmationMessage(true);
            handleConfirmationMessage();
        });
        setShowConfirmation('Are you sure you want to send the following update file to the below systems? ' + file);
    };

    const generateTransferScriptArray = (fileDownloadLink, file, checksum) => {
        const arrayOfTransferScripts = [];
        systemsToUpdate.map(system => {
            const transferValidatorApi = requests.getFullUrl( `update/save-transfer/${file}/${system}/${user}`);
            const handleFailedTransferApi = requests.getFullUrl( `update/fail-transfer`);
            const downloadCommand = c.downloadUpdate(fileDownloadLink, file, checksum, transferValidatorApi, handleFailedTransferApi);
            const obj = {
                downloadCommand,
                system
            };
            arrayOfTransferScripts.push(obj);
        });
        return arrayOfTransferScripts;
    };

    const fileSelectedConfirmed = async (file) => {
        const token = await getFileToken(file);
        const checksum = await getFileChecksum(file);
        const fileDownloadLink = requests.getFullUrl('update/download/') + encodeURIComponent(token) + '/' + file;
        const arrayOfTransferScripts = generateTransferScriptArray(fileDownloadLink, file, checksum);
        if (token) {
            sendFileToSystems(arrayOfTransferScripts);
        }
    };

    return (
        <div>
            <NotificationContainer />
            {showConfirmation && (
                <UpdateFilesConfirmation
                    showConfirmation={showConfirmation}
                    confirmationFunction={confirmationFunction}
                    setShowConfirmation={setShowConfirmation}
                />
            )}
            {showConfirmationMessage && (
                <UpdateFilesConfirmation
                    showConfirmation={showConfirmationMessage}
                    confirmationFunction={confirmationFunction}
                    setShowConfirmation={setShowConfirmationMessage}
                    discardOption={INFORMATIVE_MODAL_DISCARD_OPTION}
                />
            )}
            <Heading level={2}>Bulk Update</Heading>
            <hr />
            <div>
                <Heading level={3}>
                    {fileList.length !== 0 ? 'Select an update file' : 'No files have been uploaded yet. Please upload one.'}
                </Heading>
                <List>
                    {fileList.map((file) => {
                        return (
                            <ListItem className='items-list' key={file}>
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        fileSelectedNotConfirmed(file);
                                    }}
                                >
                                    {file}
                                </span>
                            </ListItem>
                        );
                    })}
                </List>
            </div>
        </div>
    );
};

export default FilesList;
