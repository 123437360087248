import { Heading, Icon, Spinner, FileInputField, List, ListItem, Size, Separator, Button, Flex } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { useState, useEffect } from 'react';
import UpdateFilesConfirmation from '../../components/UpdateFilesConfirmation';
import requests from '../../utils/requests';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const UpdateFiles = ({ user }) => {
    // Confirmation modal (send file or delete file)
    const [showConfirmation, setShowConfirmation] = useState(null);
    const [confirmationFunction, setConfirmationFunction] = useState(() => () => { });
    // Update files available
    const [fileList, setFileList] = useState([]);
    // Update file to upload to hypervision
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [errorUpload, setErrorUpload] = useState(false);

    const getFileList = async () => {
        try {
            const { data } = await requests.get('update/list');
            setFileList(data);
        } catch (_err) {
            notifyError('Server error while retrieving list');
        }
    };

    const deleteFile = async (filename) => {
        try {
            const { data } = await requests.post('update/delete_file/' + filename);
            if (!data.success) {
                throw new Error();
            }
            return true;
        } catch (_err) {
            notifyError('Server error while deleting file');
        }
        return false;
    };

    const uploadFile = async () => {
        setUploadLoading(true);
        const formData = new FormData();
        for (let i = 0; i < filesToUpload.length; i++) {
            formData.append('files', filesToUpload[i]);
        }
        formData.append('username', user);
        try {
            const { data } = await requests.post('update/new-files', formData, {
                'Content-Type': 'multipart/form-data',
            });
            const { success, savedFiles, notSavedFiles } = data;
            if (!success) {
                throw new Error();
            }
            if (notSavedFiles && notSavedFiles.length !== 0) {
                notifyError(`The file(s) ${notSavedFiles} either already exist or have invalid file name(s)`);
            }
            if (savedFiles && savedFiles.length !== 0) {
                notifySuccess(success);
            }
            await getFileList();
            setErrorUpload(false);
        } catch (error) {
            setErrorUpload(true);
            notifyError('Upload failed: Provide a .tar file containing only letters, numbers, dashes, or underscores (no spaces)');
        }
        setFilesToUpload([]);
        setUploadLoading(false);
    };

    useEffect(() => {
        getFileList();
    }, []);

    const fileToDeleteNotConfirmed = (file) => {
        setConfirmationFunction(() => () => {
            fileToDeleteConfirmed(file);
        });
        setShowConfirmation('Are you sure you want to delete the following file from the server? ' + file);
    };

    const fileToDeleteConfirmed = async (file) => {
        const result = await deleteFile(file);
        if (result) {
            await getFileList();
        }
    };

    const handleUploadFilesFromDevice = (e) => {
        const userChoseNonArchiveFiles = Array.from(e.target.files).some((file) => {
            return (
                file &&
                file.name &&
                !file.name.toLowerCase().endsWith('.tar')
            );
        });
        if (userChoseNonArchiveFiles) {
            setFilesToUpload([]);
            setErrorUpload(true);
            notifyError('Please select a .tar file');
        } else {
            setErrorUpload(false);
            setFilesToUpload(Object.values(e.target.files));
        }
    };

    const notifyError = (errorMessage) => {
        NotificationManager.error(errorMessage);
    };

    const notifySuccess = (successMessage) => {
        NotificationManager.success(successMessage);
    };

    return (
        <div>
            <NotificationContainer />
            {showConfirmation && (
                <UpdateFilesConfirmation
                    showConfirmation={showConfirmation}
                    confirmationFunction={confirmationFunction}
                    setShowConfirmation={setShowConfirmation}
                />
            )}
            <Heading level={2}>List of update files</Heading>
            <hr />
            {uploadLoading && (
                <div style={{ textAlign: 'center' }}>
                    <Spinner />
                    <br />
                    <br />
                    Uploading file to server...
                    <br />
                    Please do not close this window
                </div>
            )}
            {!uploadLoading && (
                <div>
                    <Heading level={3}>
                        {fileList.length !== 0 ? '' : 'No files have been uploaded yet. Please upload one.'}
                    </Heading>
                    <List>
                        {fileList.map((file) => {
                            return (
                                <ListItem className='list-item' key={file}>
                                    <span>
                                        {file}
                                    </span>
                                    <Icon
                                        name={VaiIcon.Cross}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            fileToDeleteNotConfirmed(file);
                                        }}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                    <Separator />
                    <Heading level={3}>Upload new update file to server</Heading>
                    <Flex alignContent="stretch" justifyContent="space-around" flexDirection="row" flexWrap="wrap-reverse" alignItems="center">
                        <div>
                            <FileInputField
                                onChange={handleUploadFilesFromDevice}
                                width={Size.XL}
                                placeholder={'Select .tar file...'}
                                fileInputProps={{
                                    multiple: true,
                                    accept: '.tar',
                                }}
                                disabled={false}
                            />
                        </div>
                        <div>
                            <Button
                                disabled={!(filesToUpload && filesToUpload.length !== 0) || errorUpload}
                                onClick={() => {
                                    uploadFile();
                                }}
                            >
                                Upload
                            </Button>
                        </div>
                    </Flex>
                </div>
            )}
        </div>
    );
};

export default UpdateFiles;
