import { Heading, Modal, BodyText, Button, ButtonType, Size } from '@vaisala/rockhopper-components';

const AutotestPopup = ({ isOpen, setIsOpen, startDiagnostic }) => {
    return (
        <Modal
            isOpen={isOpen}
            width={Size.L}
            showCloseIcon
            onDismiss={() => {
                setIsOpen(false);
            }}
            dismissOnOverlayClick={true}
        >
            <Heading level={2} className="vai-margin-top-none">
                Auto-diagnostic test
            </Heading>
            <BodyText>If system is running, measurments will be stopped during the test; but they will be restarted at the end of the test.</BodyText>
            <BodyText> Do you want to continue?</BodyText>
            <Button
                onClick={() => {
                    startDiagnostic();
                    setIsOpen(false);
                }}
            >
                Execute the test
            </Button>
            <Button
                buttonType={ButtonType.Secondary}
                onClick={() => {
                    setIsOpen(false);
                }}
            >
                Cancel
            </Button>
        </Modal>
    );
};

export default AutotestPopup;
