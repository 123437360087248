import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const OverviewChart = ({ dataAnalysis }) => {
    const [plotData, setPlotData] = useState([]);

    useEffect(() => {
        const parsedData = Object.entries(dataAnalysis).map(([key, value]) => ({
            x: [key],
            y: [value?.occurrence],
            type: 'bar',
            name: key,
        }));
        setPlotData(parsedData);
    }, []);

    return (
        <div>
            <Plot
                data={plotData}
                layout={{
                    width: 600,
                    height: 300,
                    xaxis: { visible: false },
                    yaxis: {},
                    title: 'Overview'
                }}
            />
        </div>
    );
};

export default OverviewChart;
