/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import requests from '../../utils/requests';
import { VaiColor } from '@vaisala/rockhopper-design-tokens';
import { DatePicker, Form, Select, Spinner, Checkbox, Button } from '@vaisala/rockhopper-components';
import Plot from 'react-plotly.js';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { fetchWeatherApi } from 'openmeteo';

const StatusCharts = ({ systemList, system, status, startDate, setStartDate, endDate, setEndDate, services, gpsCoordinates }) => {
    const [currentStatusHistory, setCurrentStatusHistory] = useState([]);
    const [showStatusHistory, setShowStatusHistory] = useState(true);
    const [currentActivityHistory, setCurrentActivityHistory] = useState([]);
    const [showActivityHistory, setShowActivityHistory] = useState(true);
    const [currentDataHistory, setCurrentDataHistory] = useState({});
    const [startDateTmp, setStartDateTmp] = useState(startDate);
    const [endDateTmp, setEndDateTmp] = useState(endDate);
    const [comparedSystemsTmp, setComparedSystemsTmp] = useState([]);
    const [comparedSystems, setComparedSystems] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [systemsNoData, setSystemsNoData] = useState([]);
    const [statusServices, setStatusServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showWeatherChart, setShowWeatherChart] = useState(false);
    const [weatherData, setWeatherData] = useState(null);
    const [isDataReadyForDownload, setIsDataReadyForDownload] = useState(false);

    const IS_GPS_COORDINATES_AVAILABLE = Object.values(gpsCoordinates).length !== 0;

    const nagiosStatusText = {
        0: 'OK',
        1: 'WARNING',
        2: 'CRITICAL',
        3: 'UNKNOWN',
    };

    const nagiosStatusColor = {
        0: VaiColor.AlertOk,
        1: VaiColor.AlertWarning,
        2: VaiColor.AlertError,
        3: VaiColor.Grey,
    };

    const toggleWeatherChart = () => {
        setShowWeatherChart(!showWeatherChart);
    };

    const exportToExcel = () => {
        if (isDataReadyForDownload) {
            let data = Object.values(currentDataHistory)[0] ?? [];
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Status');

            const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

            const startDateStr = format(startDate, 'yyyy-MM-dd');
            const endDateStr = format(endDate, 'yyyy-MM-dd');

            saveAs(blob, `status_${system}_${startDateStr}_${endDateStr}.xlsx`);
        }
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    };

    const getStatusHistory = async () => {
        setLoading(true);
        var newStatusHistory = [];
        var newActivityHistory = [];
        var newDataHistory = {};
        try {
            const startDateStr = format(startDate, 'yyyy-MM-dd HH:mm:ss');
            const endDateStr = format(endDate, 'yyyy-MM-dd HH:mm:ss');

            const { data: dataSta } = await requests.get(
                'monitoring/status_history/' + system + '/' + startDateStr + '/' + endDateStr + '/' + status
            );
            newStatusHistory = dataSta;

            const { data: dataAct } = await requests.post('activities/', { system, startDateStr, endDateStr });
            newActivityHistory = dataAct.activities;

            for (const s of Array(system).concat(comparedSystems)) {
                const { data } = await requests.post('monitoring/status_data_history/' + s + '/' + startDateStr + '/' + endDateStr, {
                    services: selectedServices,
                });
                if (data) {
                    newDataHistory[s] = data;
                }
            }
        } catch (_err) {
            console.log(_err);
        }
        setCurrentStatusHistory(newStatusHistory);
        setCurrentActivityHistory(newActivityHistory);
        setCurrentDataHistory(newDataHistory);
        let data = Object.values(newDataHistory)[0] ?? [];
        setIsDataReadyForDownload(data.length !== 0);
        setLoading(false);
    };

    useEffect(() => {
        if (system) {
            getStatusHistory();
        }
    }, []);

    useEffect(() => {
        setStatusServices(services);
    }, [services]);

    useEffect(() => {
        setSelectedData(selectedData);
    }, [currentDataHistory, services]);

    useEffect(() => {
        refreshChart();
    }, [
        currentDataHistory,
        selectedData,
        currentStatusHistory,
        currentActivityHistory,
        showStatusHistory,
        showActivityHistory,
        services,
        showWeatherChart,
    ]);

    useEffect(() => {
        if (system) {
            getStatusHistory();
        }
    }, [startDate, endDate, comparedSystems, selectedServices]);

    const fetchWeatherData = async () => {
        try {
            const params = {
                latitude: gpsCoordinates?.latitude,
                longitude: gpsCoordinates?.longitude,
                start_date: format(startDate, 'yyyy-MM-dd'),
                end_date: format(endDate, 'yyyy-MM-dd'),
                hourly: ['precipitation', 'cloud_cover', 'cloud_cover_low', 'cloud_cover_mid', 'cloud_cover_high'],
            };

            const url = 'https://archive-api.open-meteo.com/v1/archive';
            const responses = await fetchWeatherApi(url, params);

            const response = responses[0];

            if (response) {
                const utcOffsetSeconds = response.utcOffsetSeconds();
                const hourly = response.hourly();

                const range = (start, stop, step) => Array.from({ length: (stop - start) / step }, (_, i) => start + i * step);

                const weatherData = {
                    hourly: {
                        time: range(Number(hourly.time()), Number(hourly.timeEnd()), hourly.interval()).map((t) =>
                            format(new Date((t + utcOffsetSeconds) * 1000), 'yyyy-MM-dd HH:mm:ss')
                        ),
                        precipitation: hourly.variables(0).valuesArray(),
                        cloudCover: hourly.variables(1).valuesArray(),
                        cloudCoverLow: hourly.variables(2).valuesArray(),
                        cloudCoverMid: hourly.variables(3).valuesArray(),
                        cloudCoverHigh: hourly.variables(4).valuesArray(),
                    },
                };
                setWeatherData(weatherData);
            } else {
                console.error('No response data received');
            }
        } catch (error) {
            console.error('Error fetching weather data:', error);
        }
    };

    useEffect(() => {
        if (IS_GPS_COORDINATES_AVAILABLE) {
            fetchWeatherData();
        }
    }, [showWeatherChart, startDate, endDate]);

    const refreshChart = () => {
        var newChartData = [];
        var newSystemsNoData = [];

        // Display weather history
        if (showWeatherChart && weatherData) {
            newChartData.push(
                {
                    type: 'scatter',
                    mode: 'lines',
                    name: 'Precipitation',
                    marker: {
                        color: 'rgba(255,0,0,1.0)',
                    },
                    showlegend: true,
                    x: weatherData.hourly.time,
                    y: weatherData.hourly.precipitation,
                },
                {
                    type: 'scatter',
                    mode: 'lines',
                    name: 'Cloud cover',
                    marker: {
                        color: 'rgba(0,0,255,0.6)',
                    },
                    showlegend: true,
                    x: weatherData.hourly.time,
                    y: weatherData.hourly.cloudCover,
                },
                {
                    type: 'scatter',
                    mode: 'lines',
                    name: 'Low cloud cover',
                    marker: {
                        color: 'rgba(60,179,113,0.4)',
                    },
                    showlegend: true,
                    x: weatherData.hourly.time,
                    y: weatherData.hourly.cloudCoverLow,
                },
                {
                    type: 'scatter',
                    mode: 'lines',
                    name: 'Mid cloud cover',
                    marker: {
                        color: 'rgba(238,130,238,0.6)',
                    },
                    showlegend: true,
                    x: weatherData.hourly.time,
                    y: weatherData.hourly.cloudCoverMid,
                },
                {
                    type: 'scatter',
                    mode: 'lines',
                    name: 'High cloud cover',
                    marker: {
                        color: 'rgba(255,165,0,0.8)',
                    },
                    showlegend: true,
                    x: weatherData.hourly.time,
                    y: weatherData.hourly.cloudCoverHigh,
                    text: 'High cloud cover',
                }
            );
        }

        if (selectedData.length !== 0) {
            // Current system status history
            if (showStatusHistory && currentStatusHistory.length > 0) {
                Object.keys(nagiosStatusText).forEach((key) => {
                    newChartData.push({
                        type: 'scatter',
                        mode: 'markers',
                        name: system + ' [STATUS]',
                        marker: {
                            color: nagiosStatusColor[key],
                            size: 10,
                        },
                        showlegend: false,
                        x: currentStatusHistory.filter((obj) => obj.state == key).map((obj) => obj.state_time),
                        y: currentStatusHistory.filter((obj) => obj.state == key).map(() => 0),
                        text: currentStatusHistory
                            .filter((obj) => obj.state == key)
                            .map((obj) => '[' + nagiosStatusText[obj.state] + '] ' + obj.output),
                    });
                });
            }

            // Current system activity history
            if (showActivityHistory && currentActivityHistory.length > 0) {
                newChartData.push({
                    type: 'scatter',
                    mode: 'markers',
                    name: system + ' [ACTIVITY]',
                    marker: {
                        color: '#006795',
                        size: 15,
                    },
                    showlegend: false,
                    x: currentActivityHistory.map((obj) => obj.datetime),
                    y: currentActivityHistory.map(() => 1),
                    text: currentActivityHistory.map((obj) => '[ACTIVITY] ' + obj.activity),
                });
            }

            // All selected systems data history
            for (const s of Array(system).concat(comparedSystems)) {
                if (currentDataHistory.hasOwnProperty(s) && currentDataHistory[s].length > 0 && currentDataHistory[s][0].hasOwnProperty('datetime')) {
                    for (const dataKey of selectedData) {
                        if (currentDataHistory[s][0].hasOwnProperty(dataKey)) {
                            const yData = currentDataHistory[s].map((obj) => obj[dataKey]);

                            var atLeastOneValue = false;
                            for (const d of yData) {
                                if (!isNaN(d)) {
                                    atLeastOneValue = true;
                                    break;
                                }
                            }

                            if (atLeastOneValue) {
                                newChartData.push({
                                    type: 'scatter',
                                    name: s + ' [' + dataKey + ']',
                                    x: currentDataHistory[s].map((obj) => obj.datetime),
                                    y: yData,
                                });
                            } else {
                                newSystemsNoData.push(`${s} [${dataKey}]`);
                            }
                        } else {
                            newSystemsNoData.push(`${s} [${dataKey}]`);
                        }
                    }
                } else {
                    newSystemsNoData.push(s);
                }
            }
        }
        setChartData(newChartData);
        setSystemsNoData(newSystemsNoData);
    };

    // Graph

    const layout = {
        hovermode: 'x unified',
        autoresize: true,
        xaxis: {
            title: {
                text: 'Datetime',
            },
        },
        yaxis: {
            title: {
                text: selectedData.join('-'),
            },
        },
        autosize: true,
        uirevision: true,
    };

    const config = {
        displaylogo: false,
        responsive: false,
        scrollZoom: true,
    };

    // display the selected system
    return (
        <div>
            {loading && (
                <div style={{ textAlign: 'center' }}>
                    <Spinner />
                </div>
            )}
            {!loading && (
                <div>
                    <Form.Item label="Services">
                        <Select
                            placeholder="Select service..."
                            mode="multiple"
                            value={selectedServices}
                            onChange={(nextValue) => {
                                setSelectedServices(nextValue);
                            }}
                            onBlur={() => {
                                setSelectedServices(selectedServices);
                            }}
                        >
                            {statusServices?.map((service) => (
                                <Select.Option key={service} value={service}>
                                    {service}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Data to display">
                        <Select
                            placeholder="Select data..."
                            mode="multiple"
                            value={selectedData}
                            onChange={(nextValue) => {
                                setSelectedData(nextValue);
                            }}
                            onBlur={() => {
                                setSelectedData(selectedData);
                            }}
                        >
                            {currentDataHistory.hasOwnProperty(system) &&
                                currentDataHistory[system].length > 0 &&
                                Object.keys(currentDataHistory[system][0]).length > 0 &&
                                Object.keys(currentDataHistory[system][0])
                                    .filter((key) => {
                                        return key != 'datetime';
                                    })
                                    .map((row) => (
                                        <Select.Option key={row} value={row}>
                                            {row}
                                        </Select.Option>
                                    ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Show status history">
                        <Checkbox
                            checked={showStatusHistory}
                            onChange={() => {
                                setShowStatusHistory(!showStatusHistory);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Show activity history">
                        <Checkbox
                            checked={showActivityHistory}
                            onChange={() => {
                                setShowActivityHistory(!showActivityHistory);
                            }}
                        />
                    </Form.Item>

                    <Form.Item label="Start datetime">
                        <DatePicker
                            value={startDateTmp}
                            onChange={(date) => setStartDateTmp(date)}
                            onCalendarClose={() => {
                                setStartDate(startDateTmp);
                            }}
                            startDate={startDateTmp}
                            endDate={endDateTmp}
                            config={{
                                showTimeInput: true,
                                dateFormat: 'yyyy-MM-dd HH:mm',
                                timeFormat: 'HH:mm:ss',
                                selectsStart: true,
                                shouldCloseOnSelect: false,
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="End datetime">
                        <DatePicker
                            value={endDateTmp}
                            onChange={(date) => setEndDateTmp(date)}
                            onCalendarClose={() => {
                                setEndDate(endDateTmp);
                            }}
                            startDate={startDateTmp}
                            endDate={endDateTmp}
                            minDate={startDateTmp}
                            config={{
                                showTimeInput: true,
                                dateFormat: 'yyyy-MM-dd HH:mm',
                                timeFormat: 'HH:mm:ss',
                                selectsEnd: true,
                                shouldCloseOnSelect: false,
                            }}
                        />
                    </Form.Item>

                    <Form.Item label="Compare with">
                        <Select
                            placeholder="Select systems..."
                            mode="multiple"
                            value={comparedSystemsTmp}
                            onChange={(nextValue) => {
                                setComparedSystemsTmp(nextValue);
                                setComparedSystems(nextValue);
                            }}
                        >
                            {systemList.map((row) => (
                                <Select.Option key={row.host_name} value={row.host_name}>
                                    {row.host_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Export data">
                        <Button onClick={exportToExcel} style={{ border: 0, backgroundColor: 'hsl(154, 65%, 26%)' }} disabled={!isDataReadyForDownload}>
                            Download
                        </Button>
                        {!isDataReadyForDownload ? (
                            <p style={{ color: 'red' }}>You need to select the data for exporting</p>
                        ) : null}
                    </Form.Item>

                    <Form.Item label="Display weather">
                        <Button onClick={toggleWeatherChart} disabled={!IS_GPS_COORDINATES_AVAILABLE}>
                            {showWeatherChart ? 'Hide Weather History' : 'Show Weather History'}
                        </Button>
                        {IS_GPS_COORDINATES_AVAILABLE ? (
                            <p>Notice: Today&apos;s weather history is currently unavailable</p>
                        ) : (
                            <p style={{ color: 'red' }}>System is currently disconnected or GPS coordinates are unavailable</p>
                        )}
                    </Form.Item>

                    <div style={{ marginTop: '20px' }}>
                        <div style={{ margin: '0 auto', width: '80%' }}>
                            <Plot data={chartData} layout={layout} config={config} style={{ height: 'inherit' }} />
                        </div>
                        {systemsNoData.length > 0 && (
                            <div style={{ textAlign: 'center' }}>Following systems don&apos;t have data: {systemsNoData.join(', ')}</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default StatusCharts;
