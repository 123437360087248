import { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableCellType, Icon, Spinner } from '@vaisala/rockhopper-components';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import requests from '../../utils/requests';

const CurrentStatus = ({ system }) => {
    const [currentStatus, setCurrentStatus] = useState([]);
    const [loading, setLoading] = useState(true);

    const getCurrentStatus = async () => {
        setLoading(true);
        try {
            const { data } = await requests.get('monitoring/current_status/' + system);
            if (data) {
                setCurrentStatus(data);
            }
        } catch (_err) {
            // eslint-disable-next-line no-console
            console.log(_err);
        }
        setLoading(false);
    };

    useEffect(() => {
        getCurrentStatus();
    }, []);

    const nagiosStatusIcon = {
        0: VaiIcon.AlertOk,
        1: VaiIcon.AlertWarning,
        2: VaiIcon.AlertAlarm,
        3: VaiIcon.AlertAlarm,
    };

    const nagiosStatusColor = {
        0: VaiColor.AlertOk,
        1: VaiColor.AlertWarning,
        2: VaiColor.AlertError,
        3: VaiColor.Grey,
    };

    // Table builder
    const TableBuilder = (props) => {
        return (
            <TableBody>
                {props.data.map((row, index) => (
                    <TableRow key={`row-${index}`}>
                        <TableCell>{row.service_description}</TableCell>
                        <TableCell type={TableCellType.Centered}>
                            <div>
                                <Icon name={nagiosStatusIcon[row.current_state]} color={nagiosStatusColor[row.current_state]} />
                            </div>
                        </TableCell>
                        <TableCell>{row.last_state_change}</TableCell>
                        <TableCell>{row.output}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    // display the status
    return (
        <div>
            {loading && (
                <div style={{ textAlign: 'center' }}>
                    <Spinner />
                </div>
            )}
            {!loading && (
                <Table useHover>
                    <TableHead>
                        <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Update datetime</TableCell>
                            <TableCell>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBuilder data={currentStatus} />
                </Table>
            )}
        </div>
    );
};

export default CurrentStatus;
