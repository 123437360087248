import { useState, useEffect } from 'react';
import './Overview.css';
import {
    Paper,
    Pagination,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableCellType,
    Icon,
    InputField,
    Select,
    Form,
    Size,
    Spinner,
    Checkbox,
    Button,
} from '@vaisala/rockhopper-components';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import requests from '../../utils/requests';
import UpdateMany from './bulk-update';
import { getSystemType, isBelongToSystemType, sortVersionsAsc } from '../../utils/helpers';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { handleHTTPError, handleHTTPErrorMessage } from '../../utils/errors.service';

const Overview = ({ systemList, setSystemList, setSelectedSystem, setPageTitle, user }) => {
    // Hooks
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    // Pagination
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState([]);
    // Get filtered systems
    const [systemListFiltered, setSystemListFiltered] = useState([]);
    const [versionsList, setVersionsList] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [filterOwner, setFilterOwner] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterVersion, setFilterVersion] = useState('');
    const [filterUpload, setFilterUpload] = useState('');
    const [systemsToUpdate, setSystemsToUpdate] = useState([]);
    const [productFamily, setProductFamily] = useState('');

    /** Filter Methods */
    const handleFilterNameChange = (nextValue) => setFilterName(nextValue.target.value);
    const handleFilterOwnerChange = (nextValue) => setFilterOwner(nextValue.target.value);
    const handleFilterStatusChange = (nextValue) => setFilterStatus(nextValue);
    const handleFilterVersionChange = (nextValue) => setFilterVersion(nextValue);
    const handleFilterUploadChange = (nextValue) => {
        setFilterUpload(nextValue);
        setFilterName('');
    };

    const handleUpdateManyModal = () => {
        setVisible(!visible);
    };

    const getSystems = async () => {
        setLoading(true);
        try {
            const { data } = await requests.get('systems-db');
            if (data?.data) {
                setSystemList(data.data);
            }
        } catch (_err) {
            NotificationManager.error(handleHTTPError(_err));
            setSystemList([]);
        }
        setLoading(false);
    };

    const synchDbWithNagios = async () => {
        setLoading(true);
        try {
            const { data } = await requests.get('systems');
            if (data?.data) {
                setSystemList(data.data);
            }
        } catch (_err) {
            NotificationManager.error(handleHTTPError(_err));
        }
        setLoading(false);
    };

    const filterSystemsAndOwners = () => {
        const filtered = [];

        systemList.forEach((system) => {
            const hostname = system.host_name.toUpperCase();
            const reformattedFilterName = filterName.trim().toUpperCase();
            const owner = system.owner ? system.owner.toUpperCase() : '';
            const reformattedFilterOwner = filterOwner.trim().toUpperCase();

            const isNameMatch = hostname.includes(reformattedFilterName);
            const isOwnerMatch = reformattedFilterOwner !== '' ? owner.includes(reformattedFilterOwner) : true;
            const isStatusMatch = filterStatus === '' || system.current_state === filterStatus;
            const isUploadMatch = filterUpload === '' || isBelongToSystemType(hostname, filterUpload);
            const isVersionMatch = filterVersion === '' || system.system_version === filterVersion;

            if (isNameMatch && isOwnerMatch && isStatusMatch && isUploadMatch && isVersionMatch) {
                filtered.push(system);
            }
        });

        setSystemListFiltered(filtered);
    };

    const isChecked = (hostname) => {
        return systemsToUpdate.some((SystemName) => SystemName === hostname);
    };

    const isDisabled = (hostname) => {
        let selectedProductFamily = getSystemType(hostname);
        if (systemsToUpdate.length === 0) {
            return false;
        }
        return productFamily !== '' && selectedProductFamily !== productFamily;
    };

    const handleCheckboxChange = (hostname) => {
        if (isChecked(hostname)) {
            if (systemsToUpdate.length === 1) {
                setProductFamily('');
            }
            setSystemsToUpdate(systemsToUpdate.filter((systemName) => systemName !== hostname));
        } else {
            setSystemsToUpdate([...systemsToUpdate, hostname]);
            let selectedProductFamily = getSystemType(hostname);
            if (productFamily === '') {
                setProductFamily(selectedProductFamily);
            }
        }
    };

    function onPageChange(currentPage) {
        const startOfRecords = currentPage * recordsPerPage - recordsPerPage;
        const endOfRecords = currentPage * recordsPerPage;
        const currentRecords = systemListFiltered.slice(startOfRecords, endOfRecords);
        setPage(currentPage);
        setRecords(currentRecords);
    }

    const clearFilter = () => {
        setFilterName('');
        setFilterOwner('');
        setFilterStatus('');
        setFilterUpload('');
        setFilterVersion('');
    };

    const generateVersionsList = () => {
        let versionsListWithDuplicates = systemList.filter((system) => system?.system_version).map((system) => system?.system_version);
        let versions = [...new Set(versionsListWithDuplicates)];
        setVersionsList(sortVersionsAsc(versions));
    };

    useEffect(() => {
        setPageTitle('Overview');
        getSystems();
    }, []);

    useEffect(() => {
        filterSystemsAndOwners();
    }, [systemList, filterName, filterStatus, filterUpload, recordsPerPage, filterOwner, filterVersion]);

    useEffect(() => {
        generateVersionsList();
    }, [systemList]);

    useEffect(() => {
        onPageChange(page);
    }, [systemListFiltered]);

    // Status management
    const nagiosStatusIcon = {
        0: VaiIcon.AlertOk,
        1: VaiIcon.AlertAlarm,
        2: VaiIcon.AlertAlarm,
    };

    const nagiosStatusColor = {
        0: VaiColor.AlertOk,
        1: VaiColor.AlertError,
        2: VaiColor.Grey,
    };

    const handleDisabledCheckboxClick = (hostname) => {
        if (systemsToUpdate.length !== 0 && isDisabled(hostname)) {
            NotificationManager.info('You are unable to choose systems from various categories.');
        }
    };

    const syncSystemsOwner = async () => {
        try {
            await requests.getFromFleet('update-systems-owner/');
        } catch (error) {
            NotificationManager.error(handleHTTPErrorMessage(error));
        }
    };

    const TableBuilder = (props) => {
        return (
            <TableBody>
                {props.data.map((row, index) => (
                    <TableRow key={`row-${index}`} style={{ cursor: 'pointer' }} onClick={() => setSelectedSystem(row.host_name)}>
                        <TableCell>{row.host_name}</TableCell>
                        <TableCell type={TableCellType.Centered}>
                            <div>
                                <Icon name={nagiosStatusIcon[row.current_state]} color={nagiosStatusColor[row.current_state]} />
                            </div>
                        </TableCell>
                        <TableCell onClick={() => handleDisabledCheckboxClick(row.host_name)} type={TableCellType.Centered}>
                            <div>
                                <Checkbox
                                    checked={isChecked(row.host_name)}
                                    onChange={() => handleCheckboxChange(row.host_name)}
                                    disabled={isDisabled(row.host_name)}
                                    title={isDisabled(row.host_name) ? 'You are unable to choose systems from various categories.' : ''}
                                />
                            </div>
                        </TableCell>
                        <TableCell>{row.last_update}</TableCell>
                        <TableCell>{row.update_file}</TableCell>
                        <TableCell>{row.system_version}</TableCell>
                        <TableCell>{row.owner}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    return (
        <div>
            <NotificationContainer />
            <UpdateMany visible={visible} toggle={handleUpdateManyModal} systemsToUpdate={systemsToUpdate} user={user} />
            <div style={{ marginBottom: '5px' }}>
                <Button onClick={synchDbWithNagios}>Sync Systems</Button>
                <Button onClick={syncSystemsOwner}>Sync Owners</Button>
            </div>
            <Paper>
                {loading ? (
                    <div style={{ textAlign: 'center' }}>
                        <Spinner />
                    </div>
                ) : (
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '18%' }}>
                            <div>Number of systems: {systemListFiltered.length}</div>
                            <Button onClick={clearFilter} buttonSize={Size.S} style={{ backgroundColor: 'var(--vai-color-accent-dark-400)' }}>
                                Clear Filter
                            </Button>
                        </div>
                        <Table useHover>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <InputField placeholder="Devices" onChange={handleFilterNameChange} value={filterName} />
                                    </TableCell>
                                    <TableCell>
                                        <Select onChange={handleFilterStatusChange} value={filterStatus} dropdownMatchSelectWidth={false}>
                                            <Select.Option value="">Status: All</Select.Option>
                                            <Select.Option value="0">Status: OK/WARNING</Select.Option>
                                            <Select.Option value="1">Status: CRITICAL</Select.Option>
                                            <Select.Option value="2">Status: Not Connected</Select.Option>
                                        </Select>
                                    </TableCell>
                                    <TableCell style={{ maxWidth: '350px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Select
                                                onChange={handleFilterUploadChange}
                                                value={filterUpload}
                                                dropdownMatchSelectWidth={false}
                                                width={Size.S}
                                            >
                                                <Select.Option value="">Systems: All</Select.Option>
                                                <Select.Option value="WLS7">WLS7</Select.Option>
                                                <Select.Option value="WLS866">WLS866</Select.Option>
                                                <Select.Option value="wi02">wi02</Select.Option>
                                                <Select.Option value="wi">wi</Select.Option>
                                                <Select.Option value="WLS">WLS</Select.Option>
                                                <Select.Option value="WCS">WCS</Select.Option>
                                            </Select>
                                            <Button
                                                onClick={handleUpdateManyModal}
                                                disabled={systemsToUpdate.length === 0}
                                                title="Please select a system to enable the bulk update button"
                                                buttonSize={Size.S}
                                            >
                                                Bulk Update
                                            </Button>
                                        </div>
                                    </TableCell>
                                    <TableCell type={TableCellType.Centered}>Last Update</TableCell>
                                    <TableCell type={TableCellType.Centered}>Update File</TableCell>
                                    <TableCell type={TableCellType.Centered}>
                                        <Select onChange={handleFilterVersionChange} value={filterVersion} dropdownMatchSelectWidth={false}>
                                            <Select.Option value="">Version: All</Select.Option>
                                            {versionsList.map((version) => (
                                                <Select.Option key={version} value={version}>
                                                    {version}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    <TableCell type={TableCellType.Centered}>
                                        <InputField placeholder="Owner" onChange={handleFilterOwnerChange} value={filterOwner} />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBuilder data={records} />
                        </Table>
                        <div className="vai-margin-top-s">
                            <Pagination
                                totalItems={systemListFiltered.length}
                                itemsPerPage={recordsPerPage}
                                currentPage={page}
                                onPageChange={onPageChange}
                            />
                            <Form.Item label="Systems per page">
                                <Select
                                    placeholder="Select number..."
                                    value={recordsPerPage}
                                    width={Size.XXS}
                                    onChange={(nextValue) => {
                                        setRecordsPerPage(nextValue);
                                    }}
                                >
                                    <Select.Option value="10">10</Select.Option>
                                    <Select.Option value="15">15</Select.Option>
                                    <Select.Option value="20">20</Select.Option>
                                    <Select.Option value="25">25</Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                )}
            </Paper>
        </div>
    );
};

export default Overview;
