import { Heading, Modal, BodyText, Button, ButtonType, Size } from '@vaisala/rockhopper-components';

const UpdateConfirmationPopUp = ({ isOpen, setIsOpen, handleConfirmation, system }) => {
    return (
        <Modal
            isOpen={isOpen}
            width={Size.L}
            showCloseIcon
            onDismiss={() => {
                setIsOpen(false);
            }}
            dismissOnOverlayClick={true}
        >
            <Heading level={2} className="vai-margin-top-none">
                System Update
            </Heading>
            <BodyText>The system <span style={{ color: 'red' }}>{system}</span> is about to undergo an update. Are you sure you want to proceed?</BodyText>
            <Button
                onClick={() => {
                    handleConfirmation();
                    setIsOpen(false);
                }}
            >
                Update the system
            </Button>
            <Button
                buttonType={ButtonType.Secondary}
                onClick={() => {
                    setIsOpen(false);
                }}
            >
                Cancel
            </Button>
        </Modal>
    );
};

export default UpdateConfirmationPopUp;
