/* eslint-disable no-console */
import { Modal, Size, BodyText, Heading, List } from '@vaisala/rockhopper-components';
import requests from '../../../utils/requests';
import FilesList from './FilesList';

const UpdateMany = ({ visible, toggle, systemsToUpdate, user }) => {
    const sendFileToSystems = async (arrayOfTransferScripts) => {
        try {
            await requests.post('update/bulk-update/', { arrayOfTransferScripts, user });
        } catch (_err) {
            console.log(_err);
        }
    };

    return (
        <Modal isOpen={visible} width={Size.L} showCloseIcon onDismiss={toggle}>
            <FilesList sendFileToSystems={sendFileToSystems} toggle={toggle} user={user} systemsToUpdate={systemsToUpdate} />
            <br />
            <hr />
            <Heading level={2} className="vai-margin-top-none">
                Files will be sent to :
            </Heading>
            <List>
                {systemsToUpdate.map((system) => (
                    <BodyText key={system}>{system}</BodyText>
                ))}
            </List>
        </Modal>
    );
};

export default UpdateMany;
