import { Heading, Modal, BodyText, Button, ButtonType, Size } from '@vaisala/rockhopper-components';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import OverviewChart from './OverviewChart';
import { differenceInMinutes, differenceInMilliseconds } from 'date-fns';
import ChronologyChart from './ChronologyChart';

const LogAnalysisPopup = ({ isOpen, setIsOpen, analysisData, fileName }) => {
    const { numberOfLines, startLidarServerMain, windcubeLidarStartAcquisition, windcubeLidarStopAcquisition, fullpurge, ddaCommunicationError } =
        analysisData;

    const data = {
        startLidarServerMain,
        windcubeLidarStartAcquisition,
        windcubeLidarStopAcquisition,
        fullpurge,
        ddaCommunicationError,
    };

    const calculateDeltaTime = (startTime, timesArray, index) => {
        const startDate = new Date(startTime.split(',')[0]);
        if (index > 0 && index < timesArray.length) {
            const timeAtPreviousIndex = new Date(timesArray[index - 1].split(',')[0]);
            const deltaTime = differenceInMinutes(startDate, timeAtPreviousIndex);
            return deltaTime;
        }
        return 0;
    };

    const generateStatsSummaryData = () => {
        const formattedData = Object.values(data).map((el) => el.times);
        const maxLength = Math.max(...formattedData.map((subarray) => subarray.length));
        const resultArray = Array.from({ length: maxLength }, () => Array(formattedData.length).fill(null));
        formattedData.forEach((subarray, rowIndex) => {
            subarray.forEach((value, colIndex) => {
                resultArray[colIndex][rowIndex] = value.split(',')[0];
            });
        });

        return resultArray;
    };

    const generateSummaryData = () => {
        const sortedEvents = [];
        if (data !== undefined) {
            for (const eventName in data) {
                const times = data[eventName]?.times;
                const sortedTimes = times?.map((item) => item.split(',')[0]);
                sortedTimes?.forEach((time) => sortedEvents.push([time, eventName]));
            }
        }
        return sortedEvents.sort((a, b) => differenceInMilliseconds(new Date(a[0]), new Date(b[0])));
    };

    const exportToExcel = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const analysisFileName = `analysis_report_${fileName}.xlsx`;

        // Data for Overview sheet
        const overviewData = [
            ['Number of lines', 'Start Lidar Server', 'Acquisition', 'Stop acquisition', 'Full purge', 'DDA communication Errors'],
            [
                numberOfLines,
                startLidarServerMain?.occurrence,
                windcubeLidarStartAcquisition?.occurrence,
                windcubeLidarStopAcquisition?.occurrence,
                fullpurge?.occurrence,
                ddaCommunicationError?.occurrence,
            ],
        ];
        const overviewWs = XLSX.utils.aoa_to_sheet(overviewData);
        const overviewWscols = [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 }];
        overviewWs['!cols'] = overviewWscols;

        // Data for Start Lidar Server Main sheet
        const startLidarServerMainData = [
            ['Starts Time', 'Delta Time (min)'],
            ...startLidarServerMain?.times.map((startTime, index) => [
                startTime.split(',')[0],
                calculateDeltaTime(startTime, startLidarServerMain?.times, index),
            ]),
        ];
        const startLidarServerMainWs = XLSX.utils.aoa_to_sheet(startLidarServerMainData);
        const startLidarServerMainWscols = [{ wch: 23 }, { wch: 23 }];
        startLidarServerMainWs['!cols'] = startLidarServerMainWscols;

        // Data for Acquisation sheet
        const acquisationData = [['Time'], ...windcubeLidarStartAcquisition?.times.map((time) => [time.split(',')[0]])];
        const acquisationWs = XLSX.utils.aoa_to_sheet(acquisationData);
        const acquisationWscols = [{ wch: 23 }];
        acquisationWs['!cols'] = acquisationWscols;

        // Data for Stop Acquisation sheet
        const stopAcquisitionData = [['Time'], ...windcubeLidarStopAcquisition?.times.map((time) => [time.split(',')[0]])];
        const stopAcquisitionWs = XLSX.utils.aoa_to_sheet(stopAcquisitionData);
        const stopAcquisitionWscols = [{ wch: 23 }];
        stopAcquisitionWs['!cols'] = stopAcquisitionWscols;

        // Data for Full Purge sheet
        const fullPurgeData = [['Time'], ...fullpurge?.times.map((time) => [time.split(',')[0]])];
        const fullPurgeWs = XLSX.utils.aoa_to_sheet(fullPurgeData);
        const fullPurgeWscols = [{ wch: 23 }];
        fullPurgeWs['!cols'] = fullPurgeWscols;

        // Data for DDA Communication Errors sheet
        const ddaCommunicationErrorData = [['Time'], ...ddaCommunicationError?.times.map((time) => [time.split(',')[0]])];
        const ddaCommunicationErrorWs = XLSX.utils.aoa_to_sheet(ddaCommunicationErrorData);
        const ddaCommunicationErrorWscols = [{ wch: 23 }];
        ddaCommunicationErrorWs['!cols'] = ddaCommunicationErrorWscols;

        // Data for Stats Summary sheet
        const statsSummaryData = [
            ['Start Lidar Server', 'Acquisition', 'Stop acquisition', 'Full purge', 'DDA communication Errors'],
            ...generateStatsSummaryData(),
        ];
        const statsSummaryWs = XLSX.utils.aoa_to_sheet(statsSummaryData);
        const statsSummaryWscols = [{ wch: 23 }, { wch: 23 }, { wch: 23 }, { wch: 23 }, { wch: 23 }];
        statsSummaryWs['!cols'] = statsSummaryWscols;

        // Data for Summary sheet
        const summaryData = [['Time', 'Event'], ...generateSummaryData()];
        const summaryWs = XLSX.utils.aoa_to_sheet(summaryData);
        const summaryWscols = [{ wch: 23 }, { wch: 23 }];
        summaryWs['!cols'] = summaryWscols;

        // Create workbook
        const wb = {
            Sheets: {
                Overview: overviewWs,
                'Start Lidar Server': startLidarServerMainWs,
                Acquisation: acquisationWs,
                'Stop Acquisation': stopAcquisitionWs,
                'Full Purge': fullPurgeWs,
                'DDA Communication Errors': ddaCommunicationErrorWs,
                'Stats Summary': statsSummaryWs,
                Summary: summaryWs,
            },
            SheetNames: [
                'Overview',
                'Start Lidar Server',
                'Acquisation',
                'Stop Acquisation',
                'Full Purge',
                'DDA Communication Errors',
                'Stats Summary',
                'Summary',
            ],
        };

        // Convert workbook to buffer
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Convert buffer to Blob
        const dataExcel = new Blob([excelBuffer], { type: fileType });

        // Save Blob as file
        FileSaver.saveAs(dataExcel, analysisFileName);

        setIsOpen(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            width={Size.L}
            showCloseIcon
            onDismiss={() => {
                setIsOpen(false);
            }}
            dismissOnOverlayClick={true}
        >
            <Heading level={2} className="vai-margin-top-none">
                Analysis - {fileName}
            </Heading>
            <BodyText>
                The log file contains <strong style={{ color: 'red' }}>{numberOfLines}</strong> lines in total
            </BodyText>
            <BodyText>
                Number of starts : <strong style={{ color: 'red' }}>{startLidarServerMain?.occurrence}</strong>.
            </BodyText>
            <BodyText>
                Number of acquisition : <strong style={{ color: 'red' }}>{windcubeLidarStartAcquisition?.occurrence}</strong>.
            </BodyText>
            <BodyText>
                Number of stop acquisition : <strong style={{ color: 'red' }}>{windcubeLidarStopAcquisition?.occurrence}</strong>.
            </BodyText>
            <BodyText>
                Number of full purge : <strong style={{ color: 'red' }}>{fullpurge?.occurrence}</strong>.
            </BodyText>
            <BodyText>
                Number of DDA communication Errors : <strong style={{ color: 'red' }}>{ddaCommunicationError?.occurrence}</strong>.
            </BodyText>

            <OverviewChart dataAnalysis={data} />
            <ChronologyChart data={generateSummaryData()} />

            <BodyText>
                <strong>Please click the DOWNLOAD button to retrieve the analysis report.</strong>
            </BodyText>
            <Button
                onClick={() => {
                    setIsOpen(false);
                }}
            >
                OK
            </Button>
            <Button buttonType={ButtonType.Secondary} onClick={exportToExcel}>
                Download
            </Button>
        </Modal>
    );
};

export default LogAnalysisPopup;
