import { useState, useEffect } from 'react';
import { ContentTabs } from '@vaisala/rockhopper-components';
import { subDays } from 'date-fns';
import CurrentStatus from './CurrentStatus';
import StatusHistory from './StatusHistory';
import StatusCharts from './StatusCharts';
import { useNavigate } from 'react-router-dom';
import requests from '../../utils/requests';

const SelectedSystemStatus = ({ systemList, system, setPageTitle }) => {
    // Navigation
    const navigate = useNavigate();
    // Hooks
    const [statusSelected, setStatusSelected] = useState(null);
    const [selectedTab, setSelectedTab] = useState('currentstatus');
    const [startDate, setStartDate] = useState(subDays(new Date(), 1));
    const [endDate, setEndDate] = useState(new Date());
    const [services, setServices] = useState([]);
    const [gpsCoordinates, setGpsCoordinates] = useState({});

    const parseCoordinates = (input) => {
        const GPS_ERRORS_MESSAGES = ["Lidar Disconnected","GPS OK"];
        if(GPS_ERRORS_MESSAGES.includes(input)) {
            return {};
        }
        const [latitude, longitude] = input.split(',');
        const coordinates = {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
        };
        return coordinates;
    };

    const getCurrentStatus = async () => {
        try {
            if (system) {
                const { data } = await requests.get('monitoring/current_status/' + system);
                if (data) {
                    setServices(data?.map((el) => el?.service_description));
                    const gps = data.filter((el) => el?.service_description === 'GPS')[0]?.output;
                    setGpsCoordinates(parseCoordinates(gps));
                }
            }
        } catch (_err) {
            // eslint-disable-next-line no-console
            console.log(_err);
        }
    };

    useEffect(() => {
        getCurrentStatus();
    }, []);

    useEffect(() => {
        if (statusSelected) {
            setSelectedTab('currentstatus');
        }
    }, [statusSelected]);

    useEffect(() => {
        if (selectedTab != 'currentstatus') {
            setStatusSelected(null);
        }
    }, [selectedTab]);

    useEffect(() => {
        setPageTitle(system + ' - Status');
    }, []);

    useEffect(() => {
        if (!system) {
            navigate('/');
        }
    }, []);

    return (
        <ContentTabs
            selectedTabKey={selectedTab}
            setSelectedTabKey={setSelectedTab}
            onSelectedTabChanged={(selectedTabKey) => setSelectedTab(selectedTabKey)}
        >
            <ContentTabs.Tab tabKey="currentstatus" title="Current status">
                <CurrentStatus system={system} setStatusSelected={setStatusSelected} />
            </ContentTabs.Tab>
            <ContentTabs.Tab tabKey="statuscharts" title="Charts">
                <StatusCharts
                    systemList={systemList}
                    system={system}
                    status={statusSelected}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    services={services}
                    gpsCoordinates={gpsCoordinates}
                />
            </ContentTabs.Tab>
            <ContentTabs.Tab tabKey="statushistory" title="History">
                <StatusHistory
                    system={system}
                    setStatusSelected={setStatusSelected}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />
            </ContentTabs.Tab>
        </ContentTabs>
    );
};

export default SelectedSystemStatus;
