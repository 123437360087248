import { Heading, Modal, BodyText, Button, ButtonType, Size } from '@vaisala/rockhopper-components';

const LogSizePopup = ({ isOpen, setIsOpen, downloadLogFile, logFileSize }) => {
    return (
        <Modal
            isOpen={isOpen}
            width={Size.L}
            showCloseIcon
            onDismiss={() => {
                setIsOpen(false);
            }}
            dismissOnOverlayClick={true}
        >
            <Heading level={2} className="vai-margin-top-none">
                System logs retrieving
            </Heading>
            <BodyText>
                The whole logs files are about <strong style={{ color: 'red' }}>{logFileSize}</strong> MB
            </BodyText>
            <BodyText>Are you sure to let the system send this amount of data on this server ?</BodyText>
            <Button
                onClick={() => {
                    downloadLogFile();
                    setIsOpen(false);
                }}
            >
                Yes, get the whole logs files
            </Button>
            <Button
                buttonType={ButtonType.Secondary}
                onClick={() => {
                    setIsOpen(false);
                }}
            >
                Cancel
            </Button>
        </Modal>
    );
};

export default LogSizePopup;
