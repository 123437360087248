/* global process */
import axios from 'axios';
import { storage } from './storage';
import { generateWsBaseUrl } from './helpers';

const apiPrefix = 'api/';
const BASE_URL = process.env.REACT_APP_API_ENDPOINT + apiPrefix;

const service = {
    get: async (route, additionalHeaders) => {
        var headers = {
            'x-access-token': storage.getObject('user').token,
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Origin": "origin",
            "Access-Control-Allow-Methods": "GET,DELETE,PATCH,POST,PUT",
            "Access-Control-Allow-Headers": "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
        };

        if (additionalHeaders) {
            headers = { ...headers, ...additionalHeaders };
        }

        return await axios.get(BASE_URL + route, {
            headers: headers,
        });
    },

    getFromFleet: async (route, additionalHeaders, responseType) => {
        var headers = {
            'x-access-token': storage.getObject('user').token,
            'jssessionid': storage.getObject('user').jssessionid,
        };

        if (additionalHeaders) {
            headers = { ...headers, ...additionalHeaders };
        }

        return await axios.get(BASE_URL + route, {
            responseType: responseType,
            headers: headers,
        });
    },

    post: async (route, payload, additionalHeaders) => {
            var headers = {
                'x-access-token': storage.getObject('user').token,
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Origin": "origin",
                "Access-Control-Allow-Methods": "GET,DELETE,PATCH,POST,PUT",
                "Access-Control-Allow-Headers": "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
            };
    
            if (additionalHeaders) {
                headers = { ...headers, ...additionalHeaders };
            }
    
            return await axios.post(BASE_URL + route, payload, {
                headers: headers,
            });
    },

    login: async (username, password) => {
        const response = await axios.post(
            BASE_URL + 'authentication/login',
            {
                username: username,
                password: password,
            },
            {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                },
            }
        );
        if (Object.prototype.hasOwnProperty.call(response.data, 'x-access-token') &&
            Object.prototype.hasOwnProperty.call(response.data, 'jssessionid')) {
            storage.setObject('user', {
                username: username,
                token: response.data['x-access-token'],
                jssessionid: response.data['jssessionid'],
            });
            return true;
        }
        return false;
    },

    download: (route) => {
        axios
            .get(BASE_URL + route, {
                headers: {
                    'x-access-token': storage.getObject('user').token,
                },
                responseType: 'blob',
            })
            .then((response) => {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                let filename = response.headers['content-disposition'].split('"')[1];
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
    },

    getFullUrl: (route) => {
        if (process.env.REACT_APP_API_ENV === 'production') {
            return BASE_URL + route;
        } else {

            return window.location.origin + '/' + apiPrefix + route;
        }
    },

    getTerminalWsUrl: (system) => {
        const encodedToken = encodeURIComponent(storage.getObject('user').token);
        return (
            generateWsBaseUrl(process.env.REACT_APP_API_ENDPOINT) +
            'terminal?token=' +
            encodedToken +
            '&system=' +
            system
        );
    },

};

export default service;
