import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, ThemeMode } from '@vaisala/rockhopper-components';
import 'react-notifications/lib/notifications.css';

const themes = [
    {
        name: 'classic',
        modes: [ThemeMode.Light, ThemeMode.Dark],
    },
    {
        name: 'orca-noir',
        modes: [ThemeMode.Light, ThemeMode.Dark],
    },
];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider initialTheme='orca-noir' initialMode={ThemeMode.Light} themes={themes}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ThemeProvider>
);
