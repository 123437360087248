/* eslint-disable no-console */
/* global process */
import './LoginPage.css';
import { Login } from '@vaisala/rockhopper-components';
import { useState } from 'react';
import requests from '../../utils/requests';

const LoginPage = ({ login: loginPage }) => {
    const APP_VERSION = `Version ${process.env.REACT_APP_VERSION}`;
    // getting user info from server
    const [error, setError] = useState(false);
    const [localUser, setLocalUser] = useState({ name: '', password: '' });

    // On form submit
    const onSubmit = async () => {
        try {
            const isValid = await requests.login(localUser.name, localUser.password);
            if (isValid) {
                loginPage();
            } else {
                setError('Wrong username or password');
            }
        } catch (_err) {
            setError('Server error');
        }
    };

    // display user login page
    return (
        <div>
            <Login
                productName="HYPERVISION"
                error={error}
                privacyPolicyText="Privacy Policy"
                privacyPolicyUrl="https://www.vaisala.com/en/vaisala-policies"
                productVersion={APP_VERSION}
                copyright="© Vaisala - All rights reserved"
            >
                <Login.UsernameAndPasswordForm
                    passwordLabel="Password"
                    usernameLabel="Username"
                    forgotPasswordLabel=""
                    forgotPasswordURL=""
                    nextButtonLabel="Login"
                    onSubmit={onSubmit}
                    onPasswordChange={(e) => setLocalUser({ ...localUser, password: e.target.value })}
                    onUsernameChange={(e) => setLocalUser({ ...localUser, name: e.target.value })}
                />
            </Login>
        </div>
    );
};

export default LoginPage;
