export const getSystemType = (system) => {
    if (isStartWith(system, 'WLS') && !isStartWith(system, 'WLS7') && !isStartWith(system, 'WLS866')) {
        return 'WLS';
    } else if (isStartWith(system, 'WLS7')) {
        return 'WLS7';
    } else if (isStartWith(system, 'WLS866')) {
        return 'WLS866';
    } else if (isStartWith(system, 'wi') && !isStartWith(system, 'wi02')) {
        return 'wi';
    } else if (isStartWith(system, 'wi02')) {
        return 'wi02';
    } else if (isStartWith(system, 'WCS')) {
        return 'WCS';
    }

    return null;
};

export const isBelongToSystemType = (system, prefix) => {
    /** 'WLS' and 'wi' systems are excluded of the below list, and will be treated seperately to avoid any confusion with other system names  */
    const newProductFamilies = ['WLS7', 'WLS866', 'wi02', 'WCS'];

    if (prefix === 'WLS') {
        if (isStartWith(system, 'WLS') && !isStartWith(system, 'WLS7') && !isStartWith(system, 'WLS866')) {
            return true;
        }
    } else if (prefix === 'wi') {
        if (isStartWith(system, 'wi') && !isStartWith(system, 'wi02')) {
            return true;
        }
    } else if (newProductFamilies.includes(prefix)) {
        return getSystemType(system) === prefix;
    }

    return false;
};

export const filterByPrefix = (array, prefix) => {
    return array.filter(function (systemName) {
        let system = systemName.host_name;
        return isStartWith(system, prefix);
    });
};

export const isStartWith = (name, prefix) => {
    let nameInUpperCase = name.toUpperCase();
    let prefixInUpperCase = prefix.toUpperCase();
    return nameInUpperCase.startsWith(prefixInUpperCase);
};

export const generateWindCubePassword = (system) => {
    return 'LEO_' + system.substr(-4, 2) + '_WLS_' + system.substr(-2);
};

export const generateWsBaseUrl = (url) => {
    return url.replace('https:', 'wss:').replace('http:', 'ws:');
};

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const convertBytes = (size) => {
    let unitIndex = 0;
    let byteSize = parseInt(size, 10) || 0;

    while (byteSize >= 1024 && unitIndex < units.length - 1) {
        byteSize /= 1024;
        unitIndex++;
    }

    const decimalPlaces = byteSize < 10 && unitIndex > 0 ? 1 : 0;
    const formattedSize = byteSize.toFixed(decimalPlaces);

    return `${formattedSize} ${units[unitIndex]}`;
};

export const sortVersionsDesc = (versions) => {
    return versions.sort((a, b) => {
        const aParts = a.split('.').map(Number);
        const bParts = b.split('.').map(Number);

        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            const aVal = aParts[i] !== undefined ? aParts[i] : 0;
            const bVal = bParts[i] !== undefined ? bParts[i] : 0;

            if (aVal > bVal) {
                return -1;
            }
            if (aVal < bVal) {
                return 1;
            }
        }

        return 0;
    });
};

export const sortVersionsAsc = (versions) => {
    return versions.sort((a, b) => {
        const aParts = a.split('.').map(Number);
        const bParts = b.split('.').map(Number);

        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            const aVal = aParts[i] !== undefined ? aParts[i] : 0;
            const bVal = bParts[i] !== undefined ? bParts[i] : 0;

            if (aVal < bVal) {
                return -1;
            }
            if (aVal > bVal) {
                return 1;
            }
        }

        return 0;
    });
};
