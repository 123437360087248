import { useState, useEffect } from 'react';
import { format, subDays, differenceInDays } from 'date-fns';
import { Icon } from '@vaisala/rockhopper-components';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { Spinner, Form, DatePicker, Flex, Button, Heading } from '@vaisala/rockhopper-components';
import { useNavigate } from 'react-router-dom';
import requests from '../../utils/requests';
import { handleHTTPErrorMessage } from '../../utils/errors.service';
import LogSizePopup from './LogSizePopup';
import UnavailableServicePopup from './UnavailableServicePopup';
import AutotestPopup from './AutotestPopup';

const Diagnostic = ({ system, setPageTitle }) => {
    // Navigation
    const navigate = useNavigate();
    // Hooks
    const [showLogSizePopup, setShowLogSizePopup] = useState(false);
    const [showUnavailableServicePopup, setShowUnavailableServicePopup] = useState(false);
    const [showAutotestPopup, setShowAutotestPopup] = useState(false);
    const [logFileSize, setLogFileSize] = useState({});
    const [logFileName, setLogFileName] = useState({});
    const [diagnosticsResults, setDiagnosticsResults] = useState('');
    const [startDate, setStartDate] = useState(subDays(new Date(), 1));
    const [endDate, setEndDate] = useState(new Date());
    const [startDateTmp, setStartDateTmp] = useState(startDate);
    const [endDateTmp, setEndDateTmp] = useState(endDate);
    const [isDateRangeValid, setIsDateRangeValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [areOldLogsAvailable, setAreOldLogsAvailable] = useState(false);

    // Define endpoints
    const URL = {
        downloadLogFile: `advanced-diagnostic/downloadlogs/${system}/${logFileName}`,
        oldLogs: `advanced-diagnostic/old-logs/${system}`,
        autoDiagnostic: `advanced-diagnostic/startdiagnostic/${system}`,
        autotestDownloadAction: `advanced-diagnostic/autotestDownloadAction/${system}`,
    };

    const validateDateRange = (endDate, startDate) => {
        const MAX_ALLOWED_DIFFERENCE = 14;
        const MIN_ALLOWED_DIFFERENCE = 0;
        const dateDifference = differenceInDays(endDate, startDate);
        const isRangeValid = dateDifference >= MIN_ALLOWED_DIFFERENCE && dateDifference <= MAX_ALLOWED_DIFFERENCE;
        setIsDateRangeValid(isRangeValid);
    };

    const getLogFileInformation = async () => {
        try {
            setLoading(true);
            const formattedStartDate = format(startDate, 'yyyy-MM-dd');
            const formattedEndDate = format(endDate, 'yyyy-MM-dd');
            const logFileInformationUrl = `advanced-diagnostic/startdownload/${system}/${formattedStartDate}/${formattedEndDate}`;
            const { data } = await requests.getFromFleet(logFileInformationUrl);
            setLoading(false);
            const { logFileInformation } = data;
            const isLogExists = logFileInformation && logFileInformation?.size !== null;
            if (isLogExists) {
                setLogFileSize(logFileInformation?.size);
                setLogFileName(logFileInformation?.fileName);
                setShowLogSizePopup(true);
            } else {
                setShowUnavailableServicePopup(true);
            }
        } catch (error) {
            setLoading(false);
            handleHTTPErrorMessage(error);
        }
    };

    const downloadFile = (data, filename) => {
        try {
            const blob = new Blob([data], { type: 'application/octet-stream' });
            if (navigator.msSaveBlob) {
                // For Internet Explorer and Edge
                navigator.msSaveBlob(blob, filename);
            } else {
                // For other browsers
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            handleHTTPErrorMessage(error);
        }
    };

    const downloadLogFile = async () => {
        try {
            const apiResponseType = 'arraybuffer';
            const { data } = await requests.getFromFleet(URL.downloadLogFile, null, apiResponseType);
            downloadFile(data, logFileName);
            setAreOldLogsAvailable(true);
        } catch (error) {
            handleHTTPErrorMessage(error);
        }
    };

    const downloadOldLogs = async () => {
        try {
            const lastDownloadedLogFile = await getLastDownloadedLogFileName();
            if (lastDownloadedLogFile !== '') {
                setLogFileName(lastDownloadedLogFile);
                await downloadLogFile();
            } else {
                setAreOldLogsAvailable(false);
            }
        } catch (error) {
            handleHTTPErrorMessage(error);
        }
    };

    const getLastDownloadedLogFileName = async () => {
        try {
            const { data } = await requests.getFromFleet(URL.oldLogs);
            setAreOldLogsAvailable(data !== '');
            return data;
        } catch (error) {
            handleHTTPErrorMessage(error);
        }
    };

    const startDiagnostic = async () => {
        try {
            setLoading(true);
            setDiagnosticsResults('');
            const { data } = await requests.getFromFleet(URL.autoDiagnostic);
            setLoading(false);
            setDiagnosticsResults(data);
            setShowAutotestPopup(false);
        } catch (error) {
            handleHTTPErrorMessage(error);
            setLoading(false);
        }
    };

    const downloadDiagnosticReport = async () => {
        try {
            const currentDate = new Date();
            const formattedDate = format(currentDate, 'yyyy-MM-dd_HH-mm-ss');
            const fileName = `autotest_${system}_${formattedDate}_.txt`;
            downloadFile(diagnosticsResults, fileName);
            // Create a log when the user exports the configuration
            await requests.getFromFleet(URL.autotestDownloadAction);
        } catch (error) {
            handleHTTPErrorMessage(error);
        }
    };

    useEffect(() => {
        getLastDownloadedLogFileName();
        setPageTitle(system + ' - Diagnostic');
        if (!system) {
            navigate('/');
        }
    }, []);

    return (
        <div>
            <LogSizePopup isOpen={showLogSizePopup} setIsOpen={setShowLogSizePopup} downloadLogFile={downloadLogFile} logFileSize={logFileSize} />
            <UnavailableServicePopup isOpen={showUnavailableServicePopup} setIsOpen={setShowUnavailableServicePopup} />
            <AutotestPopup isOpen={showAutotestPopup} setIsOpen={setShowAutotestPopup} startDiagnostic={startDiagnostic} />
            {loading && (
                <div style={{ textAlign: 'center' }}>
                    <Spinner />
                </div>
            )}
            {!loading && (
                <div>
                    <Flex alignContent="flex-start" justifyContent="space-around" flexDirection="column" alignItems="flex-start">
                        <Flex
                            alignContent="stretch"
                            justifyContent="center"
                            flexDirection="row"
                            alignItems="center"
                            style={{ marginLeft: '25%', marginBottom: '5%' }}
                        >
                            <Icon name={VaiIcon.AlertWarning} color={VaiColor.AlertWarning} style={{ fontSize: '50px', marginRight: '20px' }} />
                            <Heading level={3} style={{ textAlign: 'center' }}>
                                Be careful before using the following features :
                                <br />
                                This could alterate the system behavior during these advanced operations (Measurment stop).
                            </Heading>
                        </Flex>
                        <Flex justifyContent="space-between" flexDirection="row" alignItems="center" style={{ width: '780px' }}>
                            <Icon name={VaiIcon.Download} color={VaiColor.AlertOk} style={{ fontSize: '50px' }} />
                            <Heading level={3}>
                                Please select time range to select appropriate systems logs to download according event to diagnose :
                            </Heading>
                        </Flex>
                        <Flex justifyContent="space-around" flexDirection="row" alignItems="center" style={{ width: '100%' }}>
                            <Flex
                                alignContent="center"
                                justifyContent="space-between"
                                flexDirection="row"
                                alignItems="center"
                                style={{ width: '100%' }}
                            >
                                <div style={{ width: '500px' }}></div>
                                <Flex justifyContent="space-between" flexDirection="column" alignItems="center">
                                    <Form.Item label="Start date">
                                        <DatePicker
                                            value={startDateTmp}
                                            onChange={(date) => setStartDateTmp(date)}
                                            onCalendarClose={() => {
                                                setStartDate(startDateTmp);
                                                validateDateRange(endDate, startDateTmp);
                                            }}
                                            startDate={startDateTmp}
                                            endDate={endDateTmp}
                                            config={{
                                                showTimeInput: true,
                                                dateFormat: 'yyyy-MM-dd',
                                                selectsStart: true,
                                                shouldCloseOnSelect: false,
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item label="End date">
                                        <DatePicker
                                            value={endDateTmp}
                                            onChange={(date) => setEndDateTmp(date)}
                                            onCalendarClose={() => {
                                                setEndDate(endDateTmp);
                                                validateDateRange(endDateTmp, startDate);
                                            }}
                                            startDate={startDateTmp}
                                            endDate={endDateTmp}
                                            minDate={startDateTmp}
                                            config={{
                                                showTimeInput: true,
                                                dateFormat: 'yyyy-MM-dd',
                                                selectsEnd: true,
                                                shouldCloseOnSelect: false,
                                            }}
                                        />
                                    </Form.Item>
                                </Flex>
                                <Button onClick={downloadOldLogs} disabled={!areOldLogsAvailable}>
                                    Download last log files
                                </Button>
                                <Button onClick={getLogFileInformation} disabled={!isDateRangeValid}>
                                    Upload logs from system up to the server
                                </Button>
                            </Flex>
                        </Flex>
                        <Flex justifyContent="space-around" flexDirection="row" alignItems="center" style={{ width: '100%' }}>
                            {isDateRangeValid ? null : (
                                <p style={{ color: 'red' }}>
                                    Time range is not valid. Please choose another dates with a maximum range of 14 days between them.
                                </p>
                            )}
                        </Flex>

                        <Flex
                            alignContent="stretch"
                            justifyContent="space-between"
                            flexDirection="row"
                            alignItems="center"
                            style={{ width: '870px' }}
                        >
                            <Icon name={VaiIcon.CenterMap} color={VaiColor.AlertOk} style={{ fontSize: '50px' }} />
                            <Heading level={3}>
                                This action will stop measurments, and then execute an auto-diagnostic test on the system, checking every device.
                                <br /> Please check that, the system is running nominally few minutes after the auto-test results are displayed.
                            </Heading>
                        </Flex>
                        <Flex justifyContent="space-between" flexDirection="row" alignItems="center" style={{ width: '100%' }}>
                            <Flex
                                alignContent="center"
                                justifyContent="space-between"
                                flexDirection="row"
                                alignItems="center"
                                style={{ width: '100%' }}
                            >
                                <div style={{ width: '60%' }}></div>
                                <Button onClick={downloadDiagnosticReport} disabled={diagnosticsResults === ''}>
                                    Download auto-diagnostic test result (.txt)
                                </Button>
                                <Button onClick={() => setShowAutotestPopup(true)}>Execute the auto-diagnostic test</Button>
                            </Flex>
                        </Flex>
                        <Flex justifyContent="center" flexDirection="row" alignItems="center" style={{ width: '100%' }}>
                            {diagnosticsResults === '' ? null : (
                                <pre style={{ padding: '2em', background: 'rgba(128,128,128,0.2)' }}>{diagnosticsResults}</pre>
                            )}
                        </Flex>
                    </Flex>
                </div>
            )}
        </div>
    );
};

export default Diagnostic;
