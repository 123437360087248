import { Heading, Modal, BodyText, Button, Size } from '@vaisala/rockhopper-components';

const UnavailableServicePopup = ({ isOpen, setIsOpen, }) => {
    return (
        <Modal
            isOpen={isOpen}
            width={Size.S}
            showCloseIcon
            onDismiss={() => {
                setIsOpen(false);
            }}
            dismissOnOverlayClick={true}
        >
            <Heading level={2} className="vai-margin-top-none">
                System logs retrieving
            </Heading>
            <BodyText>
                <span style={{ color: 'red' }}>Service unavailable. Please try again later.</span> 
            </BodyText>
            <Button
                onClick={() => {
                    setIsOpen(false);
                }}
            >
                Ok
            </Button>
        </Modal>
    );
};

export default UnavailableServicePopup;
