import { Button, Modal, Size } from '@vaisala/rockhopper-components';
import { useState, useEffect } from 'react';
import { Terminal } from 'xterm';
import 'xterm/css/xterm.css';
import { AttachAddon } from 'xterm-addon-attach';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import requests from '../../utils/requests';
import './Terminal.css';
import UpdateFiles from '../Update-manager';
import commands from '../../utils/commands';
import { useNavigate } from "react-router-dom";
import UpdateConfirmationPopUp from './UpdateConfirmationPopUp';

const SelectedSystemTerminal = ({ system, setPageTitle }) => {
    // Navigation
    const navigate = useNavigate();
    // Terminal
    const terminal = new Terminal();
    // Terminal websocket
    const [errorWebsocket, setErrorWebsocket] = useState(false);
    const [connectedAtLeastOnce, setConnectedAtLeastOnce] = useState(false);
    const [openUpdateConfirmationPopUp, setOpenUpdateConfirmationPopUp] = useState(false);
    const { readyState, sendMessage, getWebSocket } = useWebSocket(requests.getTerminalWsUrl(system), {
        // eslint-disable-next-line no-unused-vars
        shouldReconnect: (_closeEvent) => true,
        reconnectInterval: 10000,
    });

    const initTerminal = (readyState) => {
        if (readyState == ReadyState.OPEN) {
            setErrorWebsocket(false);
            const attachAddon = new AttachAddon(getWebSocket());
            terminal.loadAddon(attachAddon);
            if (!connectedAtLeastOnce) {
                terminal.open(document.getElementById('terminal'));
                terminal.resize(150, 45); // If changed, must be changed also in backend
            }
            setConnectedAtLeastOnce(true);
        } else if (readyState == ReadyState.CLOSED) {
            if (connectedAtLeastOnce) {
                setErrorWebsocket('Connection lost with lidar, will try again...');
            } else {
                setErrorWebsocket('Did not succeed to connect to lidar, will try again...');
            }
        }
    };

    // Update files
    const [showUpdateFiles, setShowUpdateFiles] = useState(false);

    const updateSystem = () => {
        sendMessage(commands.executeUpdate(system));
    };

    // React events
    useEffect(() => {
        setPageTitle(system + ' - Terminal');
    }, []);

    useEffect(() => {
        initTerminal(readyState);
    }, [readyState]);

    useEffect(() => {
        if (!system) {
            navigate('/');
        }
    }, []);

    return (
        <div>
            <UpdateConfirmationPopUp isOpen={openUpdateConfirmationPopUp} setIsOpen={setOpenUpdateConfirmationPopUp} handleConfirmation={updateSystem} system={system} />
            {showUpdateFiles && (
                <Modal
                    isOpen={showUpdateFiles}
                    width={Size.L}
                    showCloseIcon
                    onDismiss={() => {
                        setShowUpdateFiles(false);
                    }}
                >
                    <UpdateFiles sendMessage={sendMessage} setShowUpdateFiles={setShowUpdateFiles} />
                </Modal>
            )}
            {errorWebsocket && <div id="terminal-error">{errorWebsocket}</div>}
            {!errorWebsocket && (
                <div id="terminal-buttons">
                    <Button
                        onClick={() => setOpenUpdateConfirmationPopUp(true)}
                    >
                        Update System
                    </Button>
                </div>
            )}
            <div id="terminal"></div>
        </div>
    );
};

export default SelectedSystemTerminal;
