import { Heading, Modal, BodyText, Button, ButtonType, Size } from '@vaisala/rockhopper-components';

const UpdateFilesConfirmation = ({ showConfirmation, confirmationFunction, setShowConfirmation, discardOption }) => {
    return (
        <Modal
            isOpen={showConfirmation != null}
            width={Size.S}
            showCloseIcon
            onDismiss={() => {
                setShowConfirmation(null);
            }}
            dismissOnOverlayClick={discardOption ?? true}
        >
            <Heading level={2} className="vai-margin-top-none">
                Confirmation
            </Heading>
            <BodyText>{showConfirmation}</BodyText>
            <Button
                onClick={() => {
                    confirmationFunction();
                    setShowConfirmation(null);
                }}
            >
                Ok
            </Button>
            {
                (discardOption ?? true) ?
                    <Button
                    buttonType={ButtonType.Secondary}
                    onClick={() => {
                        setShowConfirmation(null);
                    }}
                >
                    Cancel
                </Button>
                : null
            }
        </Modal>
    );
};

export default UpdateFilesConfirmation;
