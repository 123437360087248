import { generateWindCubePassword } from "./helpers";

const c = {
    cancel: '\x03',
    newLine: '\n',

    downloadUpdate: (fileDownloadLink, filename, expectedChecksum, transferValidatorApi, handleFailedTransferApi) => {
        return (
            c.cancel +
            c.newLine +
            'mkdir -p /tmp/hypervision-update && cd /tmp/hypervision-update' +
            c.newLine +
            `if [ ! -f ${fileDownloadLink} ]; then` +
            c.newLine +
            'rm -f DOWNLOAD_FINISHED.txt' +
            c.newLine +
            'nohup sh -c "wget --tries=0 --retry-connrefused --continue ' +
            fileDownloadLink +
            ' && echo \\"### DOWNLOAD FINISHED, YOU CAN INSTALL THE UPDATE IN /tmp/hypervision-update FOLDER ###\\" > DOWNLOAD_FINISHED.txt" &' +
            c.newLine +
            'while ! test -f DOWNLOAD_FINISHED.txt; do tail -n 2 nohup.out; sleep 1; done' +
            c.newLine +
            'echo -e "\\e[32m $(cat DOWNLOAD_FINISHED.txt)\\e[0m"' +
            c.newLine +
            'echo "Download finished"' +
            c.newLine +
            // Checksum verification
            'if [ -f "/tmp/hypervision-update/' + filename + '" ]; then' +
            c.newLine +
            `actualChecksum=$(sha256sum "/tmp/hypervision-update/${filename}" | awk '{print $1}')` +
            c.newLine +
            `expectedChecksum="${expectedChecksum}"` +
            c.newLine +
            'if [ "$actualChecksum" = "$expectedChecksum" ]; then' +
            c.newLine +
            'echo "Checksum verified."' +
            c.newLine +
            `  curl -X GET ${transferValidatorApi}` +
            c.newLine +
            'else' +
            c.newLine +
            'echo "Checksum mismatch. Removing downloaded file."' +
            c.newLine +
            `  curl -X GET ${handleFailedTransferApi}` +
            c.newLine +
            'rm -f "/tmp/hypervision-update/' + filename + '"' +
            c.newLine +
            'fi' +
            c.newLine +
            'fi' +
            c.newLine +
            'else' +
            c.newLine +
            'echo "File exists"' +
            c.newLine +
            'fi' +
            c.newLine
        );
    },

    executeUpdate: (system) => {
        return (
            c.cancel +
            c.newLine +
            // TODO: Will be replaced with 'cd /home/leosphere' +
            'cd /tmp/hypervision-update' +
            c.newLine +
            'tar xvf $(ls -t *.tar | head -n1)' +
            c.newLine +
            `sudo -S <<< "${generateWindCubePassword(system)}" ./ServerInstall` +
            c.newLine
        );
    },

    createNewLogFile: (system, startDate, endDate) => {
        return (
            c.cancel +
            c.newLine +
            `echo ${generateWindCubePassword(system)} | sudo -S /usr/sbin/LimitExtractLogs.sh ${startDate} ${endDate}` +
            c.newLine +
            c.newLine
        );
    },
};

export default c;
