import { useState, useEffect } from 'react';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import {
    Application,
    SimpleNavigation,
    Header,
    Icon,
    PageContainer,
    Paper,
    Button,
    useTheme,
    ThemeMode
} from '@vaisala/rockhopper-components';
import Overview from './pages/Overview';
import SelectedSystemStatus from './pages/Status';
import SelectedSystemActivity from './pages/Activity';
import SelectedSystemTerminal from './pages/Terminal';
import LoginPage from './pages/LoginPage';
import Footer from './pages/Footer';
import { storage } from './utils/storage';
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import UpdateFiles from './pages/Update-manager';
import Diagnostic from './pages/Diagnostic';
import Analysis from './pages/Analysis';

function App() {
    // Theme
    const { setMode, mode } = useTheme();

    /* global process */
    const APP_VERSION = `${process.env.REACT_APP_VERSION}`;

    // Hooks
    const [user, setUser] = useState('');
    const [systemList, setSystemList] = useState([]);
    // left navigation
    const [navigationCollapsed, setNavigationCollapsed] = useState(false);
    const [location, setLocation] = useState('/Overview');
    const [pageTitle, setPageTitle] = useState('');
    // Selected system
    const [selectedSystem, setSelectedSystem] = useState(null);
    //  User-related
    const [logged, setLogged] = useState(false);

    function toggleTheme() {
        setMode(mode === ThemeMode.Light ? ThemeMode.Dark : ThemeMode.Light);
    }

    // login function
    const login = () => {
        setLogged(true);
    };

    //  logout function
    const logout = () => {
        storage.remove('user');
        setLogged(false);
    };

    // left navigation tabs
    const baseMenu = {
        Overview: {
            item: (
                <Link to="/" onClick={() => {
                    setLocation('Overview');
                    setSelectedSystem(null);
                }}>
                    <div id="Overview">
                        Overview
                    </div>
                </Link>
            ),
        },
    };
    const [navigationHierarchy, setNavigationHierarchy] = useState(baseMenu);

    const updateMenu = () => {
        if (selectedSystem) {
            setNavigationHierarchy({
                ...baseMenu,
                SelectedSystem: {
                    title: selectedSystem,
                    items: {
                        Status: (
                            <Link to="/status" onClick={() => setLocation('/Status')}>
                                <div id="Status">
                                    Status
                                </div>
                            </Link>
                        ),
                        Activity: (
                            <Link to="/activity" onClick={() => setLocation('/Activity')}>
                                <div id="Activity">
                                    Activity
                                </div>
                            </Link>
                        ),
                        Dignostic: (
                            <Link to="/diagnostic" onClick={() => setLocation('/Diagnostic')}>
                                <div id="Diagnostic">
                                    Diagnostic
                                </div>
                            </Link>
                        ),
                        Analysis: (
                            <Link to="/analysis" onClick={() => setLocation('/Analysis')}>
                                <div id="Analysis">
                                    Analysis
                                </div>
                            </Link>
                        ),
                        Terminal: (
                            <Link to="/terminal" onClick={() => setLocation('/Terminal')}>
                                <div id="Terminal">
                                    Terminal
                                </div>
                            </Link>
                        ),
                    },
                },
            });
        } else {
            setNavigationHierarchy(baseMenu);
        }
    };

    useEffect(() => {
        if (location == '/Overview' && selectedSystem) {
            setSelectedSystem(null);
        }
    }, [location]);

    useEffect(() => {
        updateMenu();
    }, [selectedSystem]);

    useEffect(() => {
        setUser(storage.getObject('user').username);
    }, [logged]);

    const handleUpdateManager = () => {
        setPageTitle('Update Manager');
        setLocation('/Overview');
    };

    return (
        <div className="App">
            {/* application main view : use first line to use authetification before, else use 2nd line */}
            {logged && (
                <Application>
                    <SimpleNavigation
                        location={location.startsWith('/') ? location : `/${location}`}
                        collapsed={navigationCollapsed}
                        onCollapseChange={(nexState) => setNavigationCollapsed(nexState)}
                        onSelect={(location) => setLocation(location)}
                        hierarchy={navigationHierarchy}
                        productName={
                            <div id="Update">
                                <Paper style={{ backgroundColor: 'var(--vai-meta-color-fg)', boxShadow: 'none' }}>
                                    <Link to="/update-manager">
                                        <Button onClick={handleUpdateManager} style={{ width: '100%', border: 0, backgroundColor: 'hsl(170, 23%, 48%)' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Icon name={VaiIcon.Settings} color={VaiColor.White} style={{ fontSize: '20px' }} />
                                                <p>Update Manager</p>
                                            </div>
                                        </Button>
                                    </Link>
                                </Paper>
                                <p className="rotating-color-text">HYPERVISION - v{APP_VERSION}</p>
                            </div>
                        }
                    />
                    <PageContainer>
                        <Header logoVisible={navigationCollapsed} title={pageTitle} style={{ textAlign: 'center' }}>
                            <Header.Item startIcon={<Icon name={VaiIcon.Contrast} />} title="Switch theme" onClick={toggleTheme} />
                            <Header.Menu title={storage.getObject('user').username} startIcon={<Icon name={VaiIcon.Settings} />}>
                                <Header.MenuItem onClick={logout} startIcon={<Icon name={VaiIcon.SwitchOnOff} />}>
                                    Logout
                                </Header.MenuItem>
                            </Header.Menu>
                        </Header>

                        {/* left navigation items*/}

                        <PageContainer.Content>
                            <Paper htmlId="topPaper" style={{ minHeight: '100%', minWidth: '100%', width: 'fit-content', height: 'fit-content' }}>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            <Overview
                                                systemList={systemList}
                                                setSystemList={setSystemList}
                                                setSelectedSystem={setSelectedSystem}
                                                setPageTitle={setPageTitle}
                                                user={user}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/status"
                                        element={<SelectedSystemStatus systemList={systemList} system={selectedSystem} setPageTitle={setPageTitle} />}
                                    />
                                    <Route
                                        path="/activity"
                                        element={<SelectedSystemActivity system={selectedSystem} setPageTitle={setPageTitle} />}
                                    />
                                    <Route
                                        path="/diagnostic"
                                        element={<Diagnostic system={selectedSystem} setPageTitle={setPageTitle} />}
                                    />
                                    <Route
                                        path="/analysis"
                                        element={<Analysis system={selectedSystem} setPageTitle={setPageTitle} />}
                                    />
                                    <Route
                                        path="/terminal"
                                        element={<SelectedSystemTerminal system={selectedSystem} setPageTitle={setPageTitle} />}
                                    />
                                    <Route path="/update-manager" element={<UpdateFiles user={user} />} />
                                    <Route path="*" element={<Navigate to="/" replace />}/>
                                </Routes>
                            </Paper>
                        </PageContainer.Content>
                        <Footer />
                    </PageContainer>
                </Application>
            )}

            {!logged && <LoginPage login={login} />}
        </div>
    );
}

export default App;
