import { useEffect } from 'react';
import { BodyText, } from '@vaisala/rockhopper-components';
import Plot from 'react-plotly.js';

const ChronologyChart = ({ data }) => {
    const x = [];
    const y = [];
    const generateChartData = () => {
        data.forEach((el) => {
            x.push(el[0]);
            y.push(getEventCode(el[1]));
        });
    };

    const getEventCode = (event) => {
        const events = {
            startLidarServerMain: 1,
            fullpurge: 2,
            windcubeLidarStartAcquisition: 3,
            windcubeLidarStopAcquisition: 4,
            ddaCommunicationError: 5,
        };
        return events[event];
    };

    useEffect(() => {
        generateChartData();
    }, []);

    return (
        <div>
            <Plot
                data={[
                    {
                        x: x,
                        y: y,
                        type: 'scatter',
                        mode: 'markers',
                        marker: { color: 'red' }
                    },
                ]}
                layout={{
                    width: 600,
                    height: 300,
                    xaxis: { autorange: true },
                    yaxis: { autorange: false },
                    title: 'Events Chronology'
                }}
            />
            <BodyText>
                <strong>1 : </strong>Start Lidar <strong>2 : </strong>Start acquisition <strong>3 : </strong>Stop acquisition {' '}
                <strong>4 : </strong>FullPurge<strong> 5 : </strong>DDA communication error
            </BodyText>
        </div>
    );
};

export default ChronologyChart;
