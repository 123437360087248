import { useState, useEffect } from 'react';
import { ContentTabs, Paper, Spinner } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import NewLogFileTab from './NewLogFileTab';
import { useNavigate } from 'react-router-dom';
import { format, subDays } from 'date-fns';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Form,
    DatePicker,
    InputField,
    Select,
    Pagination,
    Size,
    Button,
} from '@vaisala/rockhopper-components';
import requests from '../../utils/requests';
import { handleHTTPErrorMessage } from '../../utils/errors.service';
import LogAnalysisPopup from './LogAnalysisPopup';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const Analysis = ({ system, setPageTitle }) => {
    // Navigation
    const navigate = useNavigate();
    // Hooks
    const [selectedTab, setSelectedTab] = useState('1');
    const [loading, setLoading] = useState(false);
    // log files tab date hooks
    const [startDate, setStartDate] = useState(subDays(new Date(), 1));
    const [endDate, setEndDate] = useState(new Date());
    /** logs files tab hooks */
    const [logFilesList, setLogFilesList] = useState([]);
    const [selectedLogFileName, setSelectedLogFileName] = useState('');
    const [startDateTmp, setStartDateTmp] = useState(startDate);
    const [endDateTmp, setEndDateTmp] = useState(endDate);
    const [openAnalysisPopup, setOpenAnalysisPopup] = useState(false);
    const [analysisData, setAnalysisData] = useState({});
    // Get filtered logfiles
    const [logFilesListFiltered, setLogFilesListFiltered] = useState([]);
    const [filterName, setFilterName] = useState('');
    // Pagination
    const [logFilesPerPage, setLogfilesPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [logFiles, setLogfiles] = useState([]);
    const [newDateIsPicked, setNewDateIsPicked] = useState(true);

    useEffect(() => {
        if (!system) {
            navigate('/');
        }
        setPageTitle(system + ' - Analysis');
    }, []);

    /** Log files tab Methods */
    const analyzeLogFile = async (logFileId) => {
        try {
            setLoading(true);
            const { data } = await requests.post('analysis/logfile', { logFileId });
            setAnalysisData(data);
            setOpenAnalysisPopup(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            NotificationManager.error('Unable to process this request, Please try later');
        }
    };

    const getLogFiles = async () => {
        setLoading(true);
        var allLogFiles = [];
        try {
            const startDateStr = format(startDateTmp, 'yyyy-MM-dd');
            const endDateStr = format(endDateTmp, 'yyyy-MM-dd');
            const { data } = await requests.post('analysis/logfiles', { system, startDateStr, endDateStr });
            allLogFiles = data.logFiles;
            setLogFilesList(allLogFiles);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            handleHTTPErrorMessage(err);
        }
    };

    const handleFilterNameChange = (nextValue) => setFilterName(nextValue.target.value);

    const filterByLogFileName = () => {
        let filtered = logFilesList;
        if (filterName !== '') {
            filtered = logFilesList.filter((logFile) => logFile.filename.includes(filterName));
        }
        setLogFilesListFiltered(filtered);
    };

    function onPageChange(currentPage) {
        const startOfRecords = currentPage * logFilesPerPage - logFilesPerPage;
        const endOfRecords = currentPage * logFilesPerPage;
        const currentRecords = logFilesListFiltered.slice(startOfRecords, endOfRecords);
        setPage(currentPage);
        setLogfiles(currentRecords);
    }

    useEffect(() => {
        filterByLogFileName();
    }, [logFilesList, filterName, logFilesPerPage]);

    useEffect(() => {
        onPageChange(page);
    }, [logFilesListFiltered]);

    useEffect(() => {
        getLogFiles();
    }, [newDateIsPicked]);

    useEffect(() => {
        getLogFiles();
    }, []);

    // Table builder
    const TableBuilder = (props) => {
        return (
            <TableBody>
                {props.data.map((row, index) => (
                    <TableRow key={`row-${index}`}>
                        <TableCell>{row.filename.replace(/\.[^/.]+$/, '')}</TableCell>
                        <TableCell>{row.startDate}</TableCell>
                        <TableCell>{row.endDate}</TableCell>
                        <TableCell>
                            <Button
                                buttonSize={Size.S}
                                onClick={() => {
                                    analyzeLogFile(row.log_id);
                                    setSelectedLogFileName(row.filename.replace(/\.[^/.]+$/, ''));
                                }}
                            >
                                Analyze
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    return (
        <div>
            <NotificationContainer />
            {loading && (
                <div style={{ textAlign: 'center' }}>
                    <Spinner />
                </div>
            )}
            {!loading && (
                <Paper>
                    <Paper>
                        <ContentTabs
                            selectedTabKey={selectedTab}
                            setSelectedTabKey={setSelectedTab}
                            onSelectedTabChanged={(selectedTabKey) => setSelectedTab(selectedTabKey)}
                        >
                            <ContentTabs.Tab tabKey="1" title="Log Files" iconName={VaiIcon.DocumentLog}>
                                <div>
                                    <LogAnalysisPopup
                                        isOpen={openAnalysisPopup}
                                        setIsOpen={setOpenAnalysisPopup}
                                        analysisData={analysisData}
                                        fileName={selectedLogFileName}
                                    />

                                    <Form.Item label="Start date">
                                        <DatePicker
                                            value={startDateTmp}
                                            onChange={(date) => setStartDateTmp(date)}
                                            onCalendarClose={() => {
                                                setStartDate(startDateTmp);
                                                setNewDateIsPicked(!newDateIsPicked);
                                            }}
                                            startDate={startDateTmp}
                                            endDate={endDateTmp}
                                            config={{
                                                showTimeInput: false,
                                                dateFormat: 'yyyy-MM-dd',
                                                selectsStart: true,
                                                shouldCloseOnSelect: false,
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item label="End date">
                                        <DatePicker
                                            value={endDateTmp}
                                            onChange={(date) => setEndDateTmp(date)}
                                            onCalendarClose={() => {
                                                setEndDate(endDateTmp);
                                                setNewDateIsPicked(!newDateIsPicked);
                                            }}
                                            startDate={startDateTmp}
                                            endDate={endDateTmp}
                                            minDate={startDateTmp}
                                            config={{
                                                showTimeInput: false,
                                                dateFormat: 'yyyy-MM-dd',
                                                selectsEnd: true,
                                                shouldCloseOnSelect: false,
                                            }}
                                        />
                                    </Form.Item>
                                    <div>Number of log files: {logFilesListFiltered.length}</div>
                                    <Table useHover>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <InputField placeholder="Log File Name" value={filterName} onChange={handleFilterNameChange} />
                                                </TableCell>
                                                <TableCell>Start Date</TableCell>
                                                <TableCell>End Date</TableCell>
                                                <TableCell>Analyze</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBuilder data={logFiles} />
                                    </Table>
                                    <div className="vai-margin-top-s">
                                        <Pagination
                                            totalItems={logFilesListFiltered.length}
                                            itemsPerPage={logFilesPerPage}
                                            currentPage={page}
                                            onPageChange={onPageChange}
                                        />
                                        <Form.Item label="Log files per page">
                                            <Select
                                                placeholder="Select number..."
                                                value={logFilesPerPage}
                                                width={Size.XXS}
                                                onChange={(nextValue) => {
                                                    setLogfilesPerPage(nextValue);
                                                }}
                                            >
                                                <Select.Option value="10">10</Select.Option>
                                                <Select.Option value="15">15</Select.Option>
                                                <Select.Option value="20">20</Select.Option>
                                                <Select.Option value="25">25</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            </ContentTabs.Tab>
                            <ContentTabs.Tab tabKey="2" title="Get New Log File" iconName={VaiIcon.Edit}>
                                <NewLogFileTab
                                    system={system}
                                    setSelectedTab={setSelectedTab}
                                    setLoading={setLoading}
                                    NotificationManager={NotificationManager}
                                />
                            </ContentTabs.Tab>
                        </ContentTabs>
                    </Paper>
                </Paper>
            )}
        </div>
    );
};

export default Analysis;
