import { useState, useEffect } from 'react';
import { format, subDays } from 'date-fns';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Spinner,
    Form,
    DatePicker,
    InputField,
    Select,
    Pagination,
    Size,
} from '@vaisala/rockhopper-components';
import requests from '../../utils/requests';
import { useNavigate } from "react-router-dom";

const SelectedSystemActivity = ({ system, setPageTitle }) => {
    // Navigation
    const navigate = useNavigate();
    // Hooks
    const [activityHistory, setActivityHistory] = useState([]);
    const [startDate, setStartDate] = useState(subDays(new Date(), 1));
    const [endDate, setEndDate] = useState(new Date());
    const [startDateTmp, setStartDateTmp] = useState(startDate);
    const [endDateTmp, setEndDateTmp] = useState(endDate);
    const [loading, setLoading] = useState(true);

    const getActivityHistory = async () => {
        setLoading(true);
        var newActivityHistory = [];
        try {
            const startDateStr = format(startDate, 'yyyy-MM-dd HH:mm:ss');
            const endDateStr = format(endDate, 'yyyy-MM-dd HH:mm:ss');
            const { data } = await requests.post('activities/', { system, startDateStr, endDateStr });
            newActivityHistory = data.activities;
        } catch (_err) {
            // eslint-disable-next-line no-console
            console.log(_err);
        }
        setActivityHistory(newActivityHistory);
        setLoading(false);
    };

    useEffect(() => {
        setPageTitle(system + ' - Activity');
        getActivityHistory();
    }, []);

    useEffect(() => {
        getActivityHistory();
    }, [startDate, endDate]);

    // Get filtered activity
    const [activityHistoryFiltered, setActivityHistoryFiltered] = useState([]);
    const [filterName, setFilterName] = useState('');
    const handleFilterNameChange = (nextValue) => setFilterName(nextValue.target.value);

    const filterActivityName = () => {
        const filtered = [];
        activityHistory.forEach((s) => {
            if (s.activity.includes(filterName)) {
                filtered.push(s);
            }
        });
        setActivityHistoryFiltered(filtered);
    };

    // Pagination
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState([]);

    function onPageChange(currentPage) {
        const startOfRecords = currentPage * recordsPerPage - recordsPerPage;
        const endOfRecords = currentPage * recordsPerPage;
        const currentRecords = activityHistoryFiltered.slice(startOfRecords, endOfRecords);
        setPage(currentPage);
        setRecords(currentRecords);
    }

    useEffect(() => {
        filterActivityName();
    }, [activityHistory, filterName, recordsPerPage]);

    useEffect(() => {
        onPageChange(page);
    }, [activityHistoryFiltered]);

    useEffect(() => {
        if (!system) {
            navigate('/');
        }
    }, []);

    // Table builder
    const TableBuilder = (props) => {
        return (
            <TableBody>
                {props.data.map((row, index) => (
                    <TableRow key={`row-${index}`}>
                        <TableCell>{row.activity}</TableCell>
                        <TableCell>{row.user}</TableCell>
                        <TableCell>{row.datetime}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    return (
        <div>
            {loading && (
                <div style={{ textAlign: 'center' }}>
                    <Spinner />
                </div>
            )}
            {!loading && (
                <div>
                    <Form.Item label="Start datetime">
                        <DatePicker
                            value={startDateTmp}
                            onChange={(date) => setStartDateTmp(date)}
                            onCalendarClose={() => {
                                setStartDate(startDateTmp);
                            }}
                            startDate={startDateTmp}
                            endDate={endDateTmp}
                            config={{
                                showTimeInput: true,
                                dateFormat: 'yyyy-MM-dd HH:mm',
                                timeFormat: 'HH:mm:ss',
                                selectsStart: true,
                                shouldCloseOnSelect: false,
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="End datetime">
                        <DatePicker
                            value={endDateTmp}
                            onChange={(date) => setEndDateTmp(date)}
                            onCalendarClose={() => {
                                setEndDate(endDateTmp);
                            }}
                            startDate={startDateTmp}
                            endDate={endDateTmp}
                            minDate={startDateTmp}
                            config={{
                                showTimeInput: true,
                                dateFormat: 'yyyy-MM-dd HH:mm',
                                timeFormat: 'HH:mm:ss',
                                selectsEnd: true,
                                shouldCloseOnSelect: false,
                            }}
                        />
                    </Form.Item>
                    <div>Number of activities: {activityHistoryFiltered.length}</div>
                    <Table useHover>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <InputField placeholder="Activity" value={filterName} onChange={handleFilterNameChange} />
                                </TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Datetime</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBuilder data={records} />
                    </Table>
                    <div className="vai-margin-top-s">
                        <Pagination
                            totalItems={activityHistoryFiltered.length}
                            itemsPerPage={recordsPerPage}
                            currentPage={page}
                            onPageChange={onPageChange}
                        />
                        <Form.Item label="Activities per page">
                            <Select
                                placeholder="Select number..."
                                value={recordsPerPage}
                                width={Size.XXS}
                                onChange={(nextValue) => {
                                    setRecordsPerPage(nextValue);
                                }}
                            >
                                <Select.Option value="10">10</Select.Option>
                                <Select.Option value="15">15</Select.Option>
                                <Select.Option value="20">20</Select.Option>
                                <Select.Option value="25">25</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SelectedSystemActivity;
