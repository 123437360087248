// ErrorService.js

export const handleHTTPErrorMessage = (error) => {
    let errorMessage = 'An error occurred. Please try again.';
    if (error.response) {
        const { data, status } = error.response;
        if (status === 404) {
            errorMessage = 'Resource not found.';
        } else {
            errorMessage = data.message || `Error ${status}`;
        }
    } else if (error.request) {
        errorMessage = 'No response received from the server.';
    } else {
        errorMessage = error.message || errorMessage;
    }
    return errorMessage;
};

export const handleHTTPError = (error) => {
    let errorMessage = 'An error occurred. Please try again.';
    if (error.response) {
        const { data, status } = error.response;
        if (status === 404) {
            errorMessage = 'Resource not found.';
        } else {
            errorMessage = data.error || `Error ${status}`;
        }
    } else if (error.request) {
        errorMessage = 'No response received from the server.';
    } else {
        errorMessage = error.error || errorMessage;
    }
    return errorMessage;
};
