import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableCellType,
    Icon,
    Spinner,
    Form,
    DatePicker,
    InputField,
    Select,
    Pagination,
    Size,
} from '@vaisala/rockhopper-components';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import requests from '../../utils/requests';

const StatusHistory = ({ system, setStatusSelected, startDate, setStartDate, endDate, setEndDate }) => {
    const [statusHistory, setStatusHistory] = useState([]);
    const [startDateTmp, setStartDateTmp] = useState(startDate);
    const [endDateTmp, setEndDateTmp] = useState(endDate);
    const [loading, setLoading] = useState(true);

    const getStatusHistory = async () => {
        setLoading(true);
        var newStatusHistory = [];
        try {
            const startDateStr = format(startDate, 'yyyy-MM-dd HH:mm:ss');
            const endDateStr = format(endDate, 'yyyy-MM-dd HH:mm:ss');

            const { data } = await requests.get('monitoring/status_history/' + system + '/' + startDateStr + '/' + endDateStr);
            newStatusHistory = data.filter((obj) => {
                return obj.service_description.length > 0;
            });
        } catch (_err) {
            // eslint-disable-next-line no-console
            console.log(_err);
        }
        setStatusHistory(newStatusHistory);
        setLoading(false);
    };

    useEffect(() => {
        getStatusHistory();
    }, []);

    useEffect(() => {
        getStatusHistory();
    }, [startDate, endDate]);

    const nagiosStatusIcon = {
        0: VaiIcon.AlertOk,
        1: VaiIcon.AlertWarning,
        2: VaiIcon.AlertAlarm,
        3: VaiIcon.AlertAlarm,
    };

    const nagiosStatusColor = {
        0: VaiColor.AlertOk,
        1: VaiColor.AlertWarning,
        2: VaiColor.AlertError,
        3: VaiColor.Grey,
    };

    // Get filtered status
    const [statusHistoryFiltered, setStatusHistoryFiltered] = useState([]);
    const [filterName, setFilterName] = useState('');
    const handleFilterNameChange = (nextValue) => setFilterName(nextValue.target.value);
    const [filterStatus, setFilterStatus] = useState('');
    const handleFilterStatusChange = (nextValue) => setFilterStatus(nextValue);

    const filterStatusName = () => {
        const filtered = [];
        statusHistory.forEach((s) => {
            if (s.service_description.includes(filterName) && (filterStatus == '' || s.state == filterStatus)) { 
                filtered.push(s); 
            }
        });
        setStatusHistoryFiltered(filtered);
    };

    // Pagination
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState([]);

    function onPageChange(currentPage) {
        const startOfRecords = currentPage * recordsPerPage - recordsPerPage;
        const endOfRecords = currentPage * recordsPerPage;
        const currentRecords = statusHistoryFiltered.slice(startOfRecords, endOfRecords);
        setPage(currentPage);
        setRecords(currentRecords);
    }

    useEffect(() => {
        filterStatusName();
    }, [statusHistory, filterName, filterStatus, recordsPerPage]);

    useEffect(() => {
        onPageChange(page);
    }, [statusHistoryFiltered]);

    // Table builder
    const TableBuilder = (props) => {
        return (
            <TableBody>
                {props.data.map((row, index) => (
                    <TableRow
                        style={{ cursor: 'pointer' }}
                        key={`row-${index}`}
                        onClick={() => {
                            setStatusSelected(row.service_description);
                        }}
                    >
                        <TableCell>{row.service_description}</TableCell>
                        <TableCell type={TableCellType.Centered}>
                            <div>
                                <Icon name={nagiosStatusIcon[row.state]} color={nagiosStatusColor[row.state]} />
                            </div>
                        </TableCell>
                        <TableCell>{row.state_time}</TableCell>
                        <TableCell>{row.output}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    // display the status
    return (
        <div>
            {loading && (
                <div style={{ textAlign: 'center' }}>
                    <Spinner />
                </div>
            )}
            {!loading && (
                <div>
                    <Form.Item label="Start datetime">
                        <DatePicker
                            value={startDateTmp}
                            onChange={(date) => setStartDateTmp(date)}
                            onCalendarClose={() => {
                                setStartDate(startDateTmp);
                            }}
                            startDate={startDateTmp}
                            endDate={endDateTmp}
                            config={{
                                showTimeInput: true,
                                dateFormat: 'yyyy-MM-dd HH:mm',
                                timeFormat: 'HH:mm:ss',
                                selectsStart: true,
                                shouldCloseOnSelect: false,
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="End datetime">
                        <DatePicker
                            value={endDateTmp}
                            onChange={(date) => setEndDateTmp(date)}
                            onCalendarClose={() => {
                                setEndDate(endDateTmp);
                            }}
                            startDate={startDateTmp}
                            endDate={endDateTmp}
                            minDate={startDateTmp}
                            config={{
                                showTimeInput: true,
                                dateFormat: 'yyyy-MM-dd HH:mm',
                                timeFormat: 'HH:mm:ss',
                                selectsEnd: true,
                                shouldCloseOnSelect: false,
                            }}
                        />
                    </Form.Item>
                    <div>Number of status: {statusHistoryFiltered.length}</div>
                    <Table useHover>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <InputField placeholder="Status" value={filterName} onChange={handleFilterNameChange} />
                                </TableCell>
                                <TableCell>
                                    <Select onChange={handleFilterStatusChange} value={filterStatus} dropdownMatchSelectWidth={false}>
                                        <Select.Option value="">State: All</Select.Option>
                                        <Select.Option value="0">State: OK</Select.Option>
                                        <Select.Option value="1">State: WARNING</Select.Option>
                                        <Select.Option value="2">State: CRITICAL</Select.Option>
                                        <Select.Option value="3">State: UNKNOWN</Select.Option>
                                    </Select>
                                </TableCell>
                                <TableCell>Datetime</TableCell>
                                <TableCell>Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBuilder data={records} />
                    </Table>
                    <div className="vai-margin-top-s">
                        <Pagination
                            totalItems={statusHistoryFiltered.length}
                            itemsPerPage={recordsPerPage}
                            currentPage={page}
                            onPageChange={onPageChange}
                        />
                        <Form.Item label="Status per page">
                            <Select
                                placeholder="Select number..."
                                value={recordsPerPage}
                                width={Size.XXS}
                                onChange={(nextValue) => {
                                    setRecordsPerPage(nextValue);
                                }}
                            >
                                <Select.Option value="10">10</Select.Option>
                                <Select.Option value="15">15</Select.Option>
                                <Select.Option value="20">20</Select.Option>
                                <Select.Option value="25">25</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StatusHistory;
