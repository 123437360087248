import { useState } from 'react';
import { format, subDays, differenceInDays } from 'date-fns';
import { Form, DatePicker, Button, Paper, Flex } from '@vaisala/rockhopper-components';
import requests from '../../utils/requests';
import commands from '../../utils/commands';
import { handleHTTPErrorMessage } from '../../utils/errors.service';

const NewLogFileTab = ({ system, setSelectedTab, setLoading, NotificationManager }) => {
    // Hooks
    const [startDate, setStartDate] = useState(subDays(new Date(), 1));
    const [endDate, setEndDate] = useState(new Date());
    const [startDateTmp, setStartDateTmp] = useState(startDate);
    const [endDateTmp, setEndDateTmp] = useState(endDate);
    const [isDateRangeValid, setIsDateRangeValid] = useState(true);

    const validateDateRange = (endDate, startDate) => {
        const MAX_ALLOWED_DIFFERENCE = 150;
        const MIN_ALLOWED_DIFFERENCE = 0;
        const dateDifference = differenceInDays(endDate, startDate);
        const isRangeValid = dateDifference >= MIN_ALLOWED_DIFFERENCE && dateDifference <= MAX_ALLOWED_DIFFERENCE;
        setIsDateRangeValid(isRangeValid);
    };

    const createNewLogFile = async () => {
        setLoading(true);
        try {
            const startDateStr = format(startDateTmp, 'yyyy-MM-dd');
            const endDateStr = format(endDateTmp, 'yyyy-MM-dd');
            await requests.post('analysis/new-log-file/', {
                system,
                command: commands.createNewLogFile(system, startDateStr, endDateStr),
                startDate: startDateStr,
                endDate: endDateStr,
            });
            setLoading(false);
            setSelectedTab('1');
        } catch (err) {
            NotificationManager.error(handleHTTPErrorMessage(err));
            setLoading(false);
        }
    };

    return (
        <Paper>
            <Flex alignContent="flex-start" justifyContent="space-around" flexDirection="row" alignItems="flex-start">
                <div>
                    <Form.Item label="Start date">
                        <DatePicker
                            value={startDateTmp}
                            onChange={(date) => setStartDateTmp(date)}
                            onCalendarClose={() => {
                                setStartDate(startDateTmp);
                                validateDateRange(endDate, startDateTmp);
                            }}
                            startDate={startDateTmp}
                            endDate={endDateTmp}
                            config={{
                                showTimeInput: false,
                                dateFormat: 'yyyy-MM-dd',
                                selectsStart: true,
                                shouldCloseOnSelect: false,
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="End date">
                        <DatePicker
                            value={endDateTmp}
                            onChange={(date) => setEndDateTmp(date)}
                            onCalendarClose={() => {
                                setEndDate(endDateTmp);
                                validateDateRange(endDateTmp, startDate);
                            }}
                            startDate={startDateTmp}
                            endDate={endDateTmp}
                            minDate={startDateTmp}
                            config={{
                                showTimeInput: false,
                                dateFormat: 'yyyy-MM-dd',
                                selectsEnd: true,
                                shouldCloseOnSelect: false,
                            }}
                        />
                    </Form.Item>
                </div>
                <Button disabled={!isDateRangeValid} onClick={() => createNewLogFile()}>
                    Get the log file
                </Button>
            </Flex>
            <Flex justifyContent="space-around" flexDirection="row" alignItems="center" style={{ width: '100%' }}>
                {isDateRangeValid ? null : (
                    <p style={{ color: 'red' }}>Time range is not valid. Please choose another dates with a maximum range of 150 days between them.</p>
                )}
            </Flex>
        </Paper>
    );
};

export default NewLogFileTab;
